import React, {useState,useEffect,useRef, useMemo} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import i18next from 'i18next';
import Lottie from "react-lottie-player";
import styled from "styled-components";
// @ts-ignore

import Picker from 'react-mobile-picker'
import { swipeBehavior,on,hapticFeedback,init,closingBehavior} from '@telegram-apps/sdk';
import SlideButton from 'react-slide-button';
import {useAppAction, useAppData, useAppState} from "../../store/app/hooks";
import lottieJson from "../../lottie.json";
import {HeartIcon,HeartRedIcon} from "../../UI/Svg";
import {AppApi} from "../../api/app";

import {useUserDataOnce,useUserData, useUserState,useUserAction} from"../../store/user/hooks";
import {formatTime} from "../../utils";
import bg from "../../assets/images/BG.png";
import bag from "../../assets/images/default_bag.png";
import map from "../../assets/images/map.png";
import { Image,DivImage,ImageCatcher, Wrapper, Map, Money, MoneyHeader,
  ChooseYourBagToPlay,ImageCatcherWrapper,
  PickerChooseItem,StartGame,GradientText,GradientTextBlack,PauseBTN } from './styled';

import Header from "../../components/Header";
import "../../styles/FallingGame.css";
import VideoWait from "./components/VideoWait";
import VideoEdu from "./components/VideoEdu";


interface FloatingText {
	id: number;
	x: number;
	y: number;
}

interface TMyPod {
  id: number;
  slug: string;
  image: string;
  capacity: number;
  today: {
    value: number;
    hp_day: number;
  };
}



function Index() {
    useUserDataOnce()
    const [menuShow, setShowMenu] = useState(false);
    const {getUserData} = useUserAction()
    const {user} = useUserState()
    const {token} = useAppState()
    const [alpha, setAlpha] = useState(0); // Угол вокруг Z-оси
    const [beta, setBeta] = useState(0);   // Угол вокруг X-оси
    const [gamma, setGamma] = useState(0); // Угол вокруг Y-оси
	  const [floatingTexts, setFloatingTexts] = useState<FloatingText[]>([]);
    const [nextId, setNextId] = useState(0);
    const [isGameStaerted, setGameStarted] = useState(false);

    const [score, setScore] = useState(0);
    const [batchScore, setbatchScore] = useState(0);
    const [revenueStep,setRevenueStep] = useState(1);

    const [loosses, setLoose] = useState(0);
    
    const [howToPlay, setHowToPlay] = useState(false);
    const [pauseVar, setPauseVar] = useState(false);
    const [pickerValue, setPickerValue] = useState({ bag: 0})

    const gameContainerRef = useRef(null);
    const catcherRef = useRef(null);


    const [deltaVelocity, setDeltaVelocity] = useState(5);
    const [widthPlatform, setWidthPlatforn] = useState(45);


    const [countdown, setCountdown] = useState(-1); // до начала игры 
    
    const [gameTime, setGameTime] = useState(0); // время игры
    const [currentBag, setCurrentBag] = useState<TMyPod | null>(null); // 

 


    
    useEffect(() => { 
      if(isGameStaerted) return
      let podc = user?.mypods.find(pod => pod.id === pickerValue.bag)
      setScore(podc?.today?.value || 0)
      setCurrentBag(podc)
    }, [pickerValue, user]);


    const activePod = useMemo(() => user?.mypods.find(pod => pod.today.hp_day > 0), [user]);
    
    const pause = () => {
      setPauseVar(true)
      document.querySelectorAll(".object").forEach((object) => object.remove());
      setGameStarted(false) 
      
    }
      const playGame = (status:string) => {
      if (pauseVar) return 
      console.log('идем на сервер')
      if (token) {
        setbatchScore(0)
        AppApi.getMeta({
          'bag_id': pickerValue.bag,
          'status': status,
          'score': score,
          'score_delta': batchScore,
          'secure':'huudekkkcc'
        }, token).then((meta) => {
          
          // обновить количество жизней 
          // обновить реальный баланс или наполненность сумки
          // обновить оставшееся количество жизней сегодня 
          // обновить сложность игры
          // обновить дельту начисления 
          console.log(meta)
        })
      }
      
    }
  
    // отбивка проигрша и выйгрыша 
    useEffect(() => { 
      if (!isGameStaerted && gameTime > 3) {
          setGameTime(-3);
          playGame('loose');
          getUserData()
      }
      else if (isGameStaerted && gameTime > 3 && gameTime % 3 === 0) {
          playGame('win')
      }
    }, [gameTime]);




    useEffect(() => { 
      // эту штука запускает игру по таймеру 
      if (countdown<1 && countdown > -1 && isGameStaerted === false) {
        setGameStarted(true);
        const gameTimeInterval = setInterval(() => {
          setGameTime((prevTime) => {
          console.log(prevTime)
          if (prevTime<=-2) {
            clearInterval(gameTimeInterval);
          }
          return prevTime + 1
          });
     
        }, 1000);
      }
      else if (countdown<2 && countdown > -1 && isGameStaerted === false) {
        document.querySelectorAll(".object").forEach((object) => object.remove());
      }
    }, [countdown]);


    const setSettingBeforGame = () => { 
      if (currentBag?.slug	==='free')  {
        setWidthPlatforn(130)
        setRevenueStep(1)
      }
      else if (currentBag?.slug	==='smallbag')  {
        setWidthPlatforn(80)
        setRevenueStep(2)
      }
      else if (currentBag?.slug	==='mediumbag')  {
        setWidthPlatforn(50)
        setRevenueStep(50)
      }
      else {
        setWidthPlatforn(45)
        setRevenueStep(1)
      }
      
    }

    const startGame = () => {
      if (!user?.gotIntro ) {
        setHowToPlay(true)
        playGame('got_intro')
          getUserData()
          return
      }
      setSettingBeforGame()
      setPauseVar(false)
      setCountdown(3);
      setGameTime(0);

      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          console.log(prevCountdown)
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } 
          if (prevCountdown <= 0) {
            setCountdown(-1)
            clearInterval(countdownInterval);
            return 0;
          }
        });
      }, 1000);
    }




    
    function doHaptic(force){
      if(hapticFeedback?.isSupported()){
        hapticFeedback?.impactOccurred(force)
      }
    }
    
    const updateBagActive = (value) =>{
      setPickerValue({ bag: value.bag, })
      doHaptic('light')
    }

    if (closingBehavior.mount.isAvailable()) {
      closingBehavior.mount();
      closingBehavior.isMounted(); // true
    }

    if (closingBehavior.enableConfirmation.isAvailable()) {
      closingBehavior.enableConfirmation();
      closingBehavior.isConfirmationEnabled(); // true
    }

    if (swipeBehavior.mount.isAvailable()) {
      swipeBehavior.mount();
      swipeBehavior.isMounted(); // true
    }
    if (swipeBehavior.disableVertical.isAvailable()) {
      swipeBehavior.disableVertical();
      swipeBehavior.isVerticalEnabled(); // false
    }

    



    useEffect(() => {
      if (isGameStaerted) return
        if (user?.mypods && user?.mypods.length > 0) {
      
        if (activePod) {
          setPickerValue({ bag: activePod.id });
        }
        else{
          setPickerValue({ bag: -1 });

        }
      }

    }, [user]);






   

    useEffect(() => {
      const gameContainer = gameContainerRef.current;
      const catcher = catcherRef.current;

      const handleMouseMove = (event) => {
        const containerWidth = gameContainer.offsetWidth;
        let mouseX = event.clientX;
        if (mouseX > containerWidth - 40) mouseX = containerWidth - 40;
            catcher.style.left = `${mouseX}px`;
          };

      // Проверка наличия элемента
      if (catcher && isGameStaerted) {
          document.addEventListener("touchmove", (event) => {
            const touch = event.touches[0];
            handleMouseMove({ clientX: touch.clientX });
          });
      }


    const createObject = () => {
      const object = document.createElement("div");
      object.classList.add("object");
      object.style.left = `${Math.random() * (gameContainer.offsetWidth - 50)}px`;
      gameContainer.appendChild(object);
      const speed = Math.random() * deltaVelocity + 2; // Random speed between 3 and 10
      let objectTop = 0;
      
      const fallInterval = setInterval(() => {
        objectTop += speed;
        object.style.top = `${objectTop}px`;
        
        if (document.hidden || countdown > 1) {
          clearInterval(fallInterval);
          object.remove();
          return;
        }

        if (catcherRef.current) {
          const catcherRect = catcherRef.current.getBoundingClientRect();
          const objectRect = object.getBoundingClientRect();

          if (
            objectRect.bottom >= catcherRect.top &&
            objectRect.bottom - catcherRect.top < widthPlatform &&
            objectRect.left >= catcherRect.left &&
            objectRect.right <= catcherRect.right
          ) {
            
            setScore((prevScore) => prevScore + revenueStep);
            setbatchScore((prevScore) => prevScore + revenueStep); 

            clearInterval(fallInterval);

            setNextId((previousNextId) => {
              const newId = previousNextId + 1;
              let newFloat = { id: newId, x: 0, y: 0 };
              setFloatingTexts((prev) => [...prev, newFloat]);
              return newId;
            });
      
            object.remove();
            doHaptic('light');
          }
          
          
        }

        if (objectTop > window.innerHeight ) {
          if (isGameStaerted) {
            setLoose((prevLoose) => prevLoose + 1);
            setGameStarted(false);
          }
          clearInterval(fallInterval);
          object.remove();
        }
        
      }, 20);
    };

    const objectInterval = setInterval(createObject, 1000);

    return () => {
      document.removeEventListener("touchmove", handleMouseMove);
      clearInterval(objectInterval);
    };
  }, [isGameStaerted]);

    // const removeListener = on('deviceOrientationChanged', payload => {
    //   console.log('Viewport changed:', payload);
    //   setAccel(payload);
    // });

    return (
        <Wrapper >
          <Header/>
   

            {  !isGameStaerted ? (<VideoEdu howToPlay={howToPlay} onStop={() => setHowToPlay(false)}/>) : null  }
            

            {  !isGameStaerted && !howToPlay?  (<VideoWait/>) : null }
            
          
            {  isGameStaerted ? ( <Map>
                {/* <Link to={`map/?token=${token}`} style={{display:'flex',width:'100%',margin:10}}>
                  <Image  style={{}} src={map}/>
                </Link> */}
                 <div>
                   <Money  style={{fontSize:'14px',opacity:'0.2'}}>Заработано</Money>
                    <Money>{score}/{currentBag?.capacity}D </Money>
                    <Money style={{opacity:'0.2',fontSize:'30px'}}>{gameTime} сек </Money>
                    <PauseBTN onClick={()=> pause()}>
                      ПАУЗА
                    </PauseBTN>

                 </div>
                    <Money style={{color:'red',}}>
                        <span className='hp' style={{flexDirection:'column',alignItems:'end', justifyContent:'space-between',display:'flex', opacity:'0.6'}}> 
                            {Array.from({ length: 3  }).map((_, index) => (
                              currentBag?.today.hp_day > index ?   <HeartRedIcon width={15}/> : <HeartIcon width={15}/>
                            ))}
                          </span> 
                    </Money>
                  </Map>) : null
              }

              {
                !isGameStaerted && !howToPlay ? (
                    <StartGame style={{zIndex:100}}>
                      {
                        countdown > -1 ? (
                            <div style={{color:'#000'}}>{countdown}</div>
                        ) : (
                          <>
                            { currentBag ? (
                              <GradientText  type="button" onClick={()=>startGame()} >НАЧАТЬ ИГРУ</GradientText>
                            ) :  <GradientText  type="button" style={{filter: 'grayscale(100%)', opacity:0.6,fontSize:24 }} >ЗАКОНЧИЛИСЬ ЖИЗНИ</GradientText>}
                            <GradientTextBlack type="button" onClick={()=>setHowToPlay(true)}>пройти обучение </GradientTextBlack>
                          </>
                        )
                      }
                    </StartGame>) : null
              }


                { activePod && !isGameStaerted ? (
                  <ChooseYourBagToPlay>
                    <Picker 
                      style={{ 
                        width: '250px',
                        maskImage: 'linear-gradient(to top, transparent, transparent 5%, white 50%, white 50%, transparent 95%, transparent)',
                        transition: 'all 0.3s ease-in-out',
                        userSelect: 'none', // Prevent text selection
                        zIndex:100
                      }} 
                      className='pickerBlock'
                      value={pickerValue} 
                      itemHeight={100}
                      // height={200}
                      onChange={(value, key) => updateBagActive(value)}
                    >
                      <Picker.Column key='bag' name='bag'>
                        {user?.mypods?.map(option => (
                          option?.today?.hp_day >= 1 ? (
                          <Picker.Item  key={option.id} value={option.id}>
                            {({ selected }) => (
                            <PickerChooseItem selected={selected}>
                            <Image style={{ height: '65px', transition: 'all 0.3s ease-in-out' }} src={option?.image}/>
                              <div className='info-bag'>
                                <div className='line-2'>
                                <span className='name'>{option.name} </span> 
                                <span className='hp'> 
                                  {Array.from({ length: 3  }).map((_, index) => (
                                    option.today.hp_day > index ?   <HeartRedIcon width={15} key={index}/> : <HeartIcon key={index} width={15}/>
                                  ))}
                                  </span> 
                                </div>
                                <span className='capacity'>{option?.today?.value}/{option?.capacity} D </span> 
                            </div>
                            </PickerChooseItem>
                            )}
                          </Picker.Item>
                          ) : null
                        
                        ))}
                        </Picker.Column>
                        {
                        countdown > -1 ? (
                        <div style={{position:'absolute',
                          background:'rgba(255,255,255,1)',
                          maskImage: 'radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.2) 70%, rgba(255,255,255,0) 100%)',
                          height:'100%',width:'100%'}}/>
                        ): null}
                      </Picker>
                    </ChooseYourBagToPlay>
                ) : null}
      
            {/* сумка для игры */}
            <div id="gameContainer" style={{}} ref={gameContainerRef}>
              { isGameStaerted ? (
                  <ImageCatcherWrapper id="catcher" ref={catcherRef}>
                    <DivImage style={{ backgroundImage: `url(${currentBag?.image})`, }}/>

                    <div style={{position:'relative'}}>
                        {floatingTexts.map((text) => (
                          <span
                            key={text.id}
                            className='floating-text'
                            style={{ top: text.y-35, left: text.x+30 }}
                          >
                            <div className='reward-float'>+ {revenueStep} </div>
                        </span>
                      ))}
                      </div>
                  </ImageCatcherWrapper>) : null
              }
           </div>
            

          </Wrapper>
    );
}

export default Index;
