import React, {useMemo} from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import {Coin} from "../../UI/Svg";
import {useAppState} from "../../store/app/hooks";
import {useUserState} from "../../store/user/hooks";
import {IScore} from "../../store/user/types";

const Wrapper = styled.div`
  width: 100%;
  max-width: fit-content;
  border-radius: 30px;
  overflow: hidden;
  background: #241F21;
  min-width: 260px;
  padding-bottom: 0;
`

const Statistic = styled.div`
  padding: 43px 10px 15px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 7px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-left: 8px;
  width: 100%;
  gap: 12px;
  min-width: 200px;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 30px;
`

const Name = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.8);
  white-space: nowrap;
`

const Value = styled.div`
  min-width: 35px;
  padding: 9px;
  height: 37px;
  border-radius: 35px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  background: rgba(253, 253, 253, 0.12);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.9);
`

const Button = styled.a`
  width: 100%;
  padding: 15px 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.01em;
  color: rgba(255, 255, 255, 0.92);
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border: none;
  outline: none;
  margin-bottom: -1px;
  &:active {
    opacity: .8;
  }
`

const typeLink = 'card'
function UserStatisticComponent({setLoading}:any) {
    const {token} = useAppState()
    const {user} = useUserState()
    const loader = () => {
      setLoading(true)
    }

    const score: IScore | null = useMemo(() => user?.score || null, [user])
    return (
        <Wrapper>
            <Statistic>
              <Name>{i18next.t('token.title')}</Name>
                  
                <Row>
                    <Name>URAN BEP20 ~ 0.65$</Name>
                    <Value> {score?.uranbep || 0}   </Value>
                    


                </Row>
                <Row>
                    <Name>URAN net  ~ 0.1$</Name>
                    <Value> {score?.urannet || 0} </Value>
                </Row>

                <Row>
                    <Name>UPOINT ~ 0.1$</Name>
                    <Value>{score?.upoint || 0}</Value>
                </Row>
                <Row>
                    <Name>Exchange ~ 1.8$</Name>
                    <Value>{score?.exchange || 0}</Value>
                </Row>
            </Statistic>
 
          <Button onClick={(event) => {
                loader()
            }} href={ `https://pay.marketgame.space/token?token=${token}`}>{i18next.t('user_statistic.buy')} </Button>
            
        </Wrapper>
    );
}

export default UserStatisticComponent;
