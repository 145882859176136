import React, {FunctionComponent} from 'react';
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg viewBox="0 0 20 20" fill='#000' {...props} >
            <g clipPath="url(#close1)">
                <path fillRule="evenodd" clipRule="evenodd" d="M7.87868 9.99998L0.482568 2.60392L2.60388 0.48259L10 7.87866L17.3961 0.482589L19.5174 2.60391L12.1213 9.99998L19.5174 17.3961L17.3961 19.5174L10 12.1213L2.60388 19.5174L0.482563 17.3961L7.87868 9.99998Z"/>
            </g>
            <defs>
                <clipPath id="close1">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </Svg>
    );

export default Icon;
