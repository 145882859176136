import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
                


            <Svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0.999999L5.39156 5.07256C5.9586 5.59841 6.83248 5.60698 7.40972 5.09236L12 0.999999" stroke="black" strokeWidth="1.1" strokeLinecap="round"/>
            </Svg>


    );

export default Icon;
