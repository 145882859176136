import React from 'react';
import styled from "styled-components";
import { Cell } from '../../views/RefViews/styled';

const UpgradeBlockTableRow = styled.div`
  display: flex;
  font-size: 10px;
  height: 52px;
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  justify-content: space-between;
  margin-top: 5px;
  color: #DEDEDE;
`
const UpgradeBlockTableRowHumanActive = styled(Cell)`
width: 36%;
margin-right: 2px;
background: rgb(85, 206, 62);
color:#000;
font-weight:600;
`
const UpgradeBlockTableRowHuman = styled(Cell)`
  width: 36%;
  margin-right: 2px;
  background: #2B2B2B;
  font-weight:600;

`
const UpgradeBlockTableRowDay = styled(Cell)`
  width: 31.3%;
  flex-direction: column;
  margin-right: 2px;
  margin-left: 2px;
  background: #2B2B2B;
`
const UpgradeBlockTableRowMonth = styled(Cell)`
  width: 31.3%;
  background: radial-gradient(50% 50% at 50% 50%, #FFDF6B 0%, #EEBE5F 100%);
  border-radius: 10px;
  color: #363636;
`
function RowComponentTeam({name, connected, totalPods, active,mypodsCount}: {name:string, connected:string, totalPods:number, active:string,mypodsCount:Array<{slug: string, count: number}>}) {
    const SMILE = {'uran_small':'🪢','uran_medium':'🎧','uran_big':'🔊'}

    return (

        <UpgradeBlockTableRow>
          {active  ? (
            <UpgradeBlockTableRowHumanActive>
            {name}   
          </UpgradeBlockTableRowHumanActive>
          ) :  (
            <UpgradeBlockTableRowHuman>
              {name}    
            </UpgradeBlockTableRowHuman>
          )}

            
            <UpgradeBlockTableRowDay>
              <div>{totalPods} шт</div>
              <div>
                {
                    mypodsCount.map((item, id) => 
                    <span style={{
                        fontSize:10, 
                        background: 'rgba(255,255,255,0.6)',
                        borderRadius: 10,
                        color: '#363636',
                        paddingTop: 1,
                        paddingBottom: 1,
                        textAlign: 'center',
                        paddingLeft: 4,
                        paddingRight: 4,
                        marginLeft: 2,
                        fontWeight: 600,
                        }}>
                      {item.count} 

                      <img width='10px' style={{marginBottom:2,marginLeft:2}} src={item.slug} alt="pods"/>
                    </span>)
                }
              </div>
            </UpgradeBlockTableRowDay>
            <UpgradeBlockTableRowMonth>{connected}</UpgradeBlockTableRowMonth>
        </UpgradeBlockTableRow>
    );
}

export default RowComponentTeam;
