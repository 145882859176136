import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AppUser} from "../../api/user";

const fetchRef = createAsyncThunk<{
    price: string,
    secret: string,
    level: number,
    link: string,
    click: number,
    reg: number,
    parent: string,
    koef: number,
    ref: number,
    team: Array<{name: string, connected: string, totalPods: number, active:any, mypodsCount:Array<{slug: string, count: number}>}>,
},
    void,
    {
        state: RootState
    }>(
    'ref/fetchRef',
    async (_, ThunkAPI) => {
        const {token} = ThunkAPI.getState().app
        const [refRes, refErr] = await handle(AppUser.getRef(token))
        if (refRes) {
            return refRes.data
        }
        if (refErr) console.log(refErr);
        return {
            price: '0',
            secret: '',
            level: 0,
            link: '',
            click:0,
            team:[],
            reg:0,
            ref:0,
            koef: 1,
            parent:'',
        }
    }
)

export default fetchRef
