import styled from 'styled-components';
import i18next from 'i18next';
import { motion } from 'framer-motion';
import BaseHeadphoneModal from './BaseHeadphoneModal';
import { Image, Info, Row, Title, RowName, Stats } from '../UserHeadphoneComponent';
import { IUserPod } from '../../../store/user/types';
import { ChargeIcon, CoinsIcon } from '../../../UI/Svg';
import { useUserState } from '../../../store/user/hooks';

interface IMutedHeadphoneModalProps extends IUserPod {
  onClose: () => void;
}

const ModalDescription = styled(motion.div)`
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`

const ModalActionLink = styled(motion.a)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: white;
  height: 52px;
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border-radius: 40px;
  margin-top: 40px;
`

const ModalPrice = styled.div`
  background: linear-gradient(180deg, #8ED25C 0%, #539D1C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
`

const ModalValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #000;
`

const typeLink = 'card'

function MutedHeadphoneModal ({image, id, onClose}: IMutedHeadphoneModalProps) {
  const { user } = useUserState()
  return (
    <BaseHeadphoneModal onClose={onClose}>
      <ModalPrice>+386 $</ModalPrice>
        <Image src={image} style={{ maxHeight: '200px'}}/>
        <ModalDescription>
        {i18next.t('muted_headphone.new_device')} 
        </ModalDescription>
        <Info>
          <Row>
              <Title>{i18next.t('muted_headphone.advantage')}</Title>
          </Row>
          <Stats>
              <Row>
                  <RowName>
                      <CoinsIcon height={16} width={12} />
                      <span>{i18next.t('muted_headphone.mining_to')}</span>
                  </RowName>
                  <ModalValue>50 $ / {i18next.t('muted_headphone.in_day')}</ModalValue>
              </Row>
              <Row>
                  <RowName>
                      <ChargeIcon height={18.75} width={12}/>
                      <span>{i18next.t('muted_headphone.time_work')}</span>
                  </RowName>
                  <ModalValue>3 ч</ModalValue>
              </Row>
              <Row>
                  <RowName>
                      <ChargeIcon height={18.75} width={12}/>
                      <span>{i18next.t('muted_headphone.potential_profit')}</span>
                  </RowName>
                  <ModalValue>1500 $ / {i18next.t('muted_headphone.in_month')}</ModalValue>
              </Row>
              <Row>
                  <RowName>
                      <ChargeIcon height={18.75} width={12}/>
                      <span>{i18next.t('muted_headphone.cost_today')}</span>
                  </RowName>
                  <ModalValue>1401 $</ModalValue>
              </Row>
          </Stats>
      </Info>
      <ModalActionLink 
        href={`https://app.marketgame.spacellet/pay-init-${typeLink}?user=${user?.uid}&pod=${12}`}
      >
        {i18next.t('muted_headphone.invest')}
      </ModalActionLink>
    </BaseHeadphoneModal>
  )
}

export default MutedHeadphoneModal;