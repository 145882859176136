import React, { useState } from "react";
import styled from "styled-components";

// Styled components for the block
const BlockWrapper = styled.div`
  color: rgba(0, 0, 0, 0.4);
  padding: 0px 20px;
  border-radius: 10px;
  width: 100%;
  text-align: start;
`;

const QuestionText = styled.p`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
`;

const Button = styled.button`
  background: transparent;
  border: 2px solid;
  border-image-slice: 1;
  border-radius: 25px; /* Adjusted to make buttons more rounded */
  overflow: hidden;
  color: rgba(0, 0, 0, 0.8);
  padding: 10px 20px;
  font-size: 17px;
  cursor: pointer;
  width: 100px;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    transition: 0.3s;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: start;
  gap: 10px;
`;

const ViralTaskBlock = () => {
    
    const [response, setResponse] = useState("");

    const handleClick = () => {
      setResponse("Ответ принят, спасибо!");
    };

      return (
        <BlockWrapper>
          <div style={{ padding: '30px 20px', margin: '20px 0px', borderRadius: 10, background: 'repeating-linear-gradient(45deg, rgba(243, 243, 243, 0.4), rgba(243, 243, 243, 0.4) 10px, rgba(224, 224, 224, 0.4) 10px, rgba(224, 224, 224, 0.4) 20px)' }}>
            <QuestionText>Ты здесь? - проверка связи</QuestionText>
            <ButtonContainer>
              <Button onClick={handleClick}>да</Button>
              <Button onClick={handleClick}>Нет</Button>
            </ButtonContainer>
            {response && (
              <div style={{ backgroundColor: 'rgba(0, 128, 0, 0.5)', padding: '10px', borderRadius: '5px', marginTop: '30px' }}>
                <p>{response}</p>
              </div>
            )}
          </div>
        </BlockWrapper>
    );
};

export default ViralTaskBlock;