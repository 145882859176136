import { configureStore } from '@reduxjs/toolkit'
import appSlice from "./app/appSlice";
import userSlice from "./user/userSlice";
import refSlice from "./ref/refSlice";

export const store = configureStore({
    reducer: {
        app: appSlice,
        user: userSlice,
        ref: refSlice,
    },
    devTools: true,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
        thunk: true, serializableCheck: false
    })
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
