import React, {useState,useContext,useEffect} from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from "react-router-dom";
import WhiteLevelView from "../../views/RefViews/WhiteLevelView";
import BlackLevelView from "../../views/RefViews/BlackLevelView";
import Preloader from "../../views/RefViews/Preloader";
import PreloaderMax from "../../views/RefViews/PreloaderMax";
import {useRefData, useRefState} from "../../store/ref/hooks";
import {QrcodeModal} from "../../components/Modals";
import Header from"../../components/Header";
import { Chart } from"../../components/Chart";
import ViralTaskBlock from"../../components/QnA";

const BlockVideo = styled.div`
    overflow: hidden;
    min-width: 250px;
    margin: 20px;
    margin-bottom: 70px;
`

function Index() {
    useRefData()
    const {level} = useRefState()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    return (
        <div style={{height:'100vh',width:'100vw', overflowY:'scroll', paddingTop:'130px', }}>
            <Header/>

            <BlockVideo
                style={{
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    borderTopRightRadius: 10,
                    borderTopLeftRadius: 10,
                    overflow: 'hidden',
                }}
                >
                <iframe
                    style={{ borderRadius: 20, overflow: "hidden",width:'100%', height:'300px'}}
                    height="200px"
                    src='https://www.youtube.com/embed/eQr2B_sPJOY?si=1rREZh4dtA6IDYOE'
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                />
                
            </BlockVideo>



            <div style={{display:'flex',flexDirection:'column',margin:'20px',gap:'10px'}}>
                <span style={{color:'rgba(0,0,0,0.9)',fontSize:'30px',fontWeight:'600'}}>Гарфик Продаж</span>
                <span style={{color:'rgba(0,0,0,0.4)',}}>Тут отражаются продажи и затраты Компании</span>
            </div>
            
            <div style={{margin:'70px 30px',marginTop:'60px', paddingTop:'15px'}}>
                <Chart/>

            </div>

            <div style={{display:'flex',flexDirection:'column',margin:'20px',gap:'10px'}}>
                <span style={{color:'rgba(0,0,0,0.9)',fontSize:'30px',fontWeight:'600'}}>Совет директоров</span>
                <span style={{color:'rgba(0,0,0,0.4)',}}>Отвечай на вопросы, приходи на встречи,  управляй делами компании и деньгами вместе с нами</span>
            </div>
            <ViralTaskBlock/>

            <div style={{height:'100px'}}/>
        </div>
    )
}

export default Index;
