/* eslint-disable camelcase */
import React from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import {ChargeIcon, EarnVerticalIcon, LightingIcon,HeartIcon} from "../../UI/Svg";
import {IUserPod} from "../../store/user/types";

interface IUserHeadphoneComponentProps extends IUserPod {
    muted?: boolean;
    onClick?: () => void;
    onSell?: () => void;
    canSell?: boolean;
    capacity?: number;
    hpTotal?: number;
    today?:any;
    hp: number;
}

export const Wrapper = styled.div<Pick<IUserHeadphoneComponentProps, 'muted'>>`
  background: ${(props) => props.muted ? `#D4D4D4` : `linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%)` };
  border-radius: 27px;
  padding: 1px;
  margin-bottom:1px;
  margin-left:2px;
  margin-right:2px;
  //min-width: 167px;
  width: fit-content;
  ${(props) => props.muted && `filter: grayscale(1)`};
`

export const Bg = styled.div<Pick<IUserHeadphoneComponentProps, 'muted'>>`
  background: ${(props) => props.muted ? `#F5F5F5` : `#fff`};
  height: 100%;
  border-radius: 27px;
  width: fit-content;
  padding: 8px 11px 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 20px;
  position: relative;
`

export const Image = styled.img`
  max-height: 180px;
  
  top:70px;
  margin: 0 auto;
  display: block;
`

export const Info = styled.div<Pick<IUserHeadphoneComponentProps, 'muted'>>`
  width: 100%;
  ${(props) => props.muted && `opacity: 0.5`};
`

export const Row = styled.div`
  display: flex;

  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`

export const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`

const Model = styled.h4`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
`

export const Stats = styled.div`
  flex-direction: column;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  gap: 7px;
  margin-top: 10px;
`

export const BlockText = styled.div`
font-size: 10px;
padding-top:5px;
padding-bottom:5px;
text-align:center;
letter-spacing:-0.2px;
background: -webkit-linear-gradient(0deg,rgb(253, 197, 88) 0%, rgb(252, 163, 93) 17.71%, rgb(252, 146, 113) 34.9%, rgb(253, 136, 159) 49.48%, rgb(253, 130, 195) 65.62%, rgb(225, 119, 209) 82.81%, rgb(170, 101, 246) 99.48%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;;
`

export const RowNameBuy = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 5px;
  span {
    font-weight: 400;
    white-space: nowrap;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #121212;
  }
  text {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #000000;
  }
`
export const RowName = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 5px;
  flex-wrap: nowrap;

  span {
    font-weight: 400;
    white-space: nowrap;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #000000;
  }
  text {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #000000;
  }
`

export const Value = styled.div`
  color: #000000;
  border: 1px solid #000000;
  background: transparent;
  border-radius: 40px;
  padding: 3px 5.5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
  min-width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
`
const PlusBattery = styled.div`
  width:3px;
  height:9px;
  border-radius: 0px 20px 20px 0px;
  background:#55CE3E;
  margin-bottom:1px;
  margin-left:50px;
`

export const ChargeValue = styled(Value)<{ charge: number }>`
  font-size: 12px;
  line-height: 12px;
  color:  #55CE3E;
  border: 1px solid #55CE3E;
  position: absolute;
  left: 10px;
  border-radius:8px;
  padding-top:6px;
`

const RechargeContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right:8px;
  align-items: center;
  width: 100%;
`

const RechargeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const RechargeSquare = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background: #F6F6F6;
  border: 0.6px solid #E2E2E2;
  border-radius: 8px;
`

const RechargeTimer = styled.div`
  font-weight: 400;
  color: #BDBDBD;
  font-size: 12px;
`

function UserHeadphoneComponent({name, onClick,onSell,canSell, today, items, image, charge, hpTotal,total,overalvalue,capacity, hp, muted, recharge_at}: IUserHeadphoneComponentProps) {
    function calculateTimeLeft (date: string) {
      const difference = new Date(date).getTime() - new Date().getTime();
      let timeLeft = { hours: 0, minutes: 0 };

      if (difference > 0) {
        timeLeft = {
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60)
        };
      }

      
      return `${timeLeft.hours} ч ${timeLeft.minutes} м`;
    };
    return (
        <Wrapper muted={muted} >
            <Bg muted={muted} style={{minWidth:180}}>
                <div style={{justifyContent:'center',marginTop:5,   }}>
                    <ChargeValue 
                      charge={charge}>
                        {today?.value.toLocaleString('ru')} / {capacity.toLocaleString('ru')} D
                        {/* <PlusBattery/> */}
                    </ChargeValue>
                <div style={{fontSize:'13px',position:'absolute',opacity:0.4,right:20,top:10, color:'#000',display:'flex',alignItems:'center',gap:'3px'}}><HeartIcon width="14"/>{hp}</div>
                

                </div>
                {recharge_at 
                  ? <RechargeContainer>
                        <RechargeWrapper>
                            <RechargeSquare>
                                <LightingIcon />
                            </RechargeSquare>
                            <RechargeTimer>
                                {calculateTimeLeft(recharge_at)}
                            </RechargeTimer>
                        </RechargeWrapper>
                    </RechargeContainer>
                  : <RechargeContainer>/</RechargeContainer>
                }
                {/* onClick={onClick} */}
                <Image  style={{bottom:20,position:'absolute'}} src={image}/>
                <Info muted={muted} style={{justifyContent:'center',alignContent:'center',alignItems:'center'}}>
               
                    
                        {/* {canSell ? (
                        
                        <Stats style={{
                          background: 'linear-gradient(90deg, rgb(253, 197, 88) 0%, rgb(252, 163, 93) 17.71%, rgb(252, 146, 113) 34.9%, rgb(253, 136, 159) 49.48%, rgb(253, 130, 195) 65.62%, rgb(225, 119, 209) 82.81%, rgb(170, 101, 246) 99.48%)',
                          borderRadius: '14px',
                          padding: '2px',
                          width:'90%',
                          margin:'auto',
                          
                          }} >
                            
                              <Row 
                            onClick={() => {
                              if (items ) {
                                if (items.length>0 ) {
                                  if (onSell){
                                      onSell()
                                    }
                                  }
                                  else{
                                    alert(i18next.t('item.alert_sell'))
                                  }
                                }
                              else{
                                alert(i18next.t('item.alert_sell'))
                              }
                              
                            }}
                            style={{width:'100%',height:'30px',background:'white',borderRadius:'14px',color:'black', backgroundColor:'white', }}>
                            <div style={{color:'#828282',fontSize:10,marginLeft:10, paddingBottom:5}}>{i18next.t('item.sell')} </div>
                            <div  style={{ fontSize:20, paddingRight:'14px',color:'#363636', fontWeight:500}}>{overalvalue ? (Math.round(overalvalue).toLocaleString('ru')) : 0} $</div>
                          </Row>
                          </Stats>
                          ):(
                              <div>
                                <BlockText >
                                Продажа доступна через 72 дня
                                </BlockText>
                               
                              <Stats style={{
                              background: 'linear-gradient(90deg, rgb(253, 197, 88) 0%, rgb(252, 163, 93) 17.71%, rgb(252, 146, 113) 34.9%, rgb(253, 136, 159) 49.48%, rgb(253, 130, 195) 65.62%, rgb(225, 119, 209) 82.81%, rgb(170, 101, 246) 99.48%)',
                              borderRadius: '14px',
                              padding: '1.2px',
                              width:'95%',
                              margin:'auto',
                              
                              }} >
                              <Row 
                                style={{width:'100%',height:'30px',background:'white',borderRadius:'14px',color:'black', backgroundColor:'white', }}>
                                  <div style={{color:'#828282',fontSize:10,marginLeft:10}}>  
                                  <BlockedIcon />
                                  </div>
                                  <div  style={{ fontSize:20, paddingRight:'14px',color:'#363636', fontWeight:500}}>{overalvalue ? (Math.round(overalvalue).toLocaleString('ru')) : 0} $</div>
                              </Row>
                              </Stats>
                              </div>
                          ) }
                         */}
                       

                    
                </Info>
            </Bg>
        </Wrapper>
    );
}

export default UserHeadphoneComponent;
