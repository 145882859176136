






import React, { FunctionComponent } from 'react';
import { Svg, SvgProps } from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
    <Svg width="29" height="16" viewBox="0 0 29 16" fill="none" {...props}>
        <line x1="1" y1="1" x2="28" y2="1" stroke="black" strokeWidth="2" strokeLinecap="round" />
        <line x1="1" y1="8" x2="28" y2="8" stroke="black" strokeWidth="2" strokeLinecap="round" />
        <line x1="1" y1="15" x2="28" y2="15" stroke="black" strokeWidth="2" strokeLinecap="round" />
    </Svg>
);

export default Icon;