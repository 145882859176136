import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchRef from "./fetchRef";
import {closeModal, showModal} from "./refSlice";

export const getRef = (state: RootState) => state.ref

export function useRefAction() {
    const dispatch = useDispatch<AppDispatch>()
    const onFetchRef = useCallback(() => {
        dispatch(fetchRef())
    }, [dispatch])
    const onShowModal = useCallback(() => {
        dispatch(showModal())
    }, [dispatch])
    const onCloseModal = useCallback(() => {
        dispatch(closeModal())
    }, [dispatch])
    return useMemo(() => ({
        onFetchRef,
        onShowModal,
        onCloseModal
    }), [
        onFetchRef,
        onShowModal,
        onCloseModal
    ])
}

export function useRefState() {
    return useSelector(getRef)
}


export function useRefData() {

    const {onFetchRef} = useRefAction()


    const getData = useCallback(() => {
        onFetchRef();
    }, [onFetchRef])

    useEffect(() => {
        onFetchRef()
        const interval: any = setInterval(() => {
            onFetchRef()
        }, 1000 * 20)
        if (interval) {
            return () => clearInterval(interval)
        }
    }, [getData])
}
