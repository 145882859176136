import React, {useState} from 'react';
import styled from "styled-components";
import i18next from 'i18next';
// import {Canvas} from "@react-three/fiber";
import {ChargeIcon, EarnVerticalIcon,HeartIcon} from "../../UI/Svg";
import {IPod} from "../../store/user/types";
import {useUserState} from "../../store/user/hooks";
import {useAppState} from "../../store/app/hooks";

// const MarketPodSceneComponent = React.lazy(() => import('./MarketPodSceneComponent'));

const Wrapper = styled.div`
  background: #F8F8F8;
  position:relative;
  border-radius: 30px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 20px;
`

const Image = styled.img`
  max-width: 130px;
  min-width: 70px;
  max-height: 130px;
  z-index:1;
`

const BackSign = styled.div`
  text-transform: capitalise;
  top:0;
  left:0px;
  z-index:0;
  max-height: 120px;
  position:absolute;
  div{
    overflow: hidden;
    width:fit-content;
    border-radius:30px;
    padding-right:40px;
    max-width:400px;
    div {
      margin-top:-8px;
      top:-10px;
      margin-left:-10px;
      font-size:100px;
      font-weight:900;
      color:#E8E8E8;
    }
}
`

const Info = styled.div`
  min-width: 200px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  z-index:1;
`

const Title = styled.h3`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  z-index:1;
`

const Model = styled.h4`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  z-index:1;
`

const Stats = styled.div`
  flex-direction: row;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  margin-top: 15px;
  z-index:1;
`

const PlusBattery = styled.div`
  position: absolute;
  width:4px;
  height:15px;
  border-radius: 0px 20px 20px 0px;
  background:#549F1E;
  right:-0.5px;
`
const TimeWork = styled.div`
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding:3px 5px 6px 5px;
  width:100%;
  margin-right:5px;
  border-radius:10px;
  position:relative;
  text-align:center;
  background:white;
  border: 1px solid #549F1E;
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color:#549F1E;
    letter-spacing: -0.01em;
  }
  div {
    font-weight:600;
    color:#549F1E;
    text-align:center;
    sup {
      color:#549F1E;
      font-size:10px;
      padding-bottom:8px;
    }
  }
  text {
    color:#D7E1CF;
    font-size:6px;
    lign-height:1px;
    padding:0px;
    margin:0px;
    position:absolute;
    left:20px;
    bottom:1px;
    text-align:center;
  }
`
const PotentialRevenue = styled.div`
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding:3px 5px 6px 5px;
  width:100%;
  margin-right:5px;
  border-radius:10px;
  text-align:center;
  color:white;
  background: linear-gradient(180deg, #8ED25C 0%, #539D1C 100%);
  span {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.01em;
  }
  div {
    text-align:center;
    font-weight:600;
    
  }
`
const RowName = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 5px;
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: #000000;
  }
`

const Value = styled.div`
  background: #55CE3E;
  border-radius: 40px;
  padding: 5px 5.5px;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  min-width: 63px;
  border: 1px solid #55CE3E;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ChargeValue = styled(Value)`
  font-size: 10px;
  letter-spacing: -0.01em;
  color: #000000;
  border: 1px solid #000000;
  background: transparent;
`

const Button = styled.div`
  width: 100%;
  cursor:pointer;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 12px;
  color: #FFFFFF;
  padding-top:18px;
  padding-bottom:12px;
  border: none;
  background: #EFEFEF;
  border-radius: 10px;
  margin-top: 10px;
  div {
    background: -webkit-linear-gradient(0deg,#FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
    font-weight:700;
    min-width:110px;
    margin-right:1px;

  }
  & > span {
    display: flex;
    color: #626161;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    height:25px;
    font-weight: 400;
    padding-left:8px;
    padding-right:10px;
    padding-bottom:12px;
  }
`

const Circle = styled.span`
  min-width: 17px;
  min-height: 17px;
  border-radius: 50%;
  background: white;
  display: block;
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
`
const typeLink = 'card'

const CanvasWrapper = styled.div`
  max-width: 100px;
  max-height: 130px;
`

function HeadphoneItemComponent({image, name, showPrice,capacityUsd,capacityRub,hpTotal, power,onClick, price, capacity, id, recharge,setLoading,slug, percentMonth}: any ) {
    const {user} = useUserState()
    const {token} = useAppState()

 

    const loader = () => {
      setLoading(true)
    }
    return (
        <Wrapper onClick={(event) => {
            onClick()
        }} 
        >
             <Image  src={image}/>
              <BackSign  >
                <div>
                  <div>{name}</div>
                </div>
                </BackSign>
            
             
            <Info>
                <div style={{fontSize:'13px',position:'absolute',opacity:0.4,right:20,top:10, color:'#000',display:'flex',alignItems:'center',gap:'3px'}}><HeartIcon width="14"/>{hpTotal}</div>
                {/* <Row>
                    <Title style={{color:'rgba(0,0,0,0)'}}>{i18next.t('whitelevel.model')}</Title>
                    <Model  onClick={(event) => {
                        window.open(`https://app.marketgame.space/tradingview/?slug=${slug}&token=${token}`)
                    }}>
                      <PlotIcon  height={18.75} width={18.75}/>
                    </Model>
                </Row> */}
                <Stats style={{width:'200px'}}>
                    {/* <Row style={{width:'50%'}}>
                        <PotentialRevenue>
                            <span>{i18next.t('item.potential')}</span>
                            <div>{percentMonth}</div>
                        </PotentialRevenue>
                    </Row> */}
                    <Row style={{width:'100%', position:'relative'}}>
                        <TimeWork>
                            <span>{i18next.t('item.time_work')}</span>
                            <div>{capacity.toLocaleString('ru')} D/день </div>
                            <div style={{fontSize:'10px',color:'rgba(0,0,0,0.3)'}}>
                              ~ {capacityUsd.toLocaleString('ru')} $ 
                              ~ {capacityRub.toLocaleString('ru')} руб.

                            </div>
                            {/* <sup>x{recharge}</sup> */}
                        </TimeWork>
                        {/* <PlusBattery/> */}
                        {/* <Value>{power}{i18next.t('item.month_income')}</Value> */}
                    </Row>

                   
                </Stats>
                

                {
                  showPrice ? (
                    <Button >
                      <span style={{}}>
                      {i18next.t('item.invest')}
                      </span>
                      
                      <div style={{flexWrap:'nowrap'}}>{price} $</div>
                  </Button>
                  
                  ) : (<Button style={{display:'flex',justifyContent:'center'}} >
                      {/* <span style={{}}>
                      Узнать
                      </span> */}
                     
                      <div style={{flexWrap:'nowrap',fontSize:'22px',}}> Получить </div>
                  </Button>)
                }
                
            </Info>
        </Wrapper>
    );
}

export default HeadphoneItemComponent;
