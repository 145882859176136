import {axiosInstance} from "./index";

export const AppUser = {
    authServer: async (requestData:any) => await axiosInstance.post('/auth/tg-auth-server',  requestData ),
    getUserDetailWeb: async (token: string) => await axiosInstance.get('/profile/user-detail-web', {headers: {'Authorization': token}}),
    getRef: async (token: string) => await axiosInstance.get('/user/my-ref', {headers: {'Authorization': token}}),
    readBanner: async (id: number, token: string) => await axiosInstance.post('/baner_message/read', { id }, {headers: {'Authorization': token}}),
    sellPod: async (id: number, token: string) => await axiosInstance.post('/sell-pod', { id }, {headers: {'Authorization': token}}),
    leadEdu: async (phone: string,name: string) => await axiosInstance.get(`/lead-request-edu?phone=${phone}&name=${name}`),
}
