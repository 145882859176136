import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} >
            <g clipPath="url(#clip0_103_2863)">
            <path d="M12 20C9.39 19.12 3 15.25 3 9C3 6.24 5.24 4 8 4C9.64 4 11.09 4.79 12 6C12.91 4.78 14.37 4 16 4C18.76 4 21 6.23 21 9C21 15.26 14.61 19.12 12 20Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_103_2863">
            <rect width="24" height="24" fill="white"/>
            </clipPath>
            </defs>

        </Svg>
    );

export default Icon;
