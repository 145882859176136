import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import fetchUser from "./fetchUser";
import {IPod, IUser, IUserPod} from "./types";

export interface AppState {
    user: IUser | null,
    pods: IPod[],
    userPods: IUserPod[]
}
const initialState: AppState = {
    user: null,
    pods:[
        {
            "id": 1,
            "name": "Small",
            "slug": "uran_small",
            "price": 9.0,
            "capacity": "4.0",
            "recharge":'1',
            "power": 0.27,
            "image": "https://app.marketgame.space/media/pods/uran1-1-min.png",
            "background": "background-1",
            "date": "17/10/2022, 03:41",
        }
    ],
    userPods: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // changeToken: (state, action: PayloadAction<string>) => {
        //     state.token = action.payload
        // },
        // toggleMusic: (state) => {
        //     state.isPlay = !state.isPlay
        // },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchUser.fulfilled, (state, action: PayloadAction<IUser | null>) => {
                state.user = action.payload;
            })
            
    }
})

// export const {changeToken, toggleMusic} = userSlice.actions
export default userSlice.reducer
