import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {TMeta} from "./types";
import fetchMeta from "./fetchMeta";

export interface AppState {
    token: string,
    meta: TMeta,
    isPlay: boolean
}

export const initMeta: TMeta = {
    "today": {
        "time": 0,
        "revenue": 0,
        "revenue_array": {
            "int": 32,
            "decimal": 6,
            "small": 0.0521,
            "text": '',
        }
    }
}

const initialState: AppState = {
    isPlay: false,
    token: '',
    meta: initMeta
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        changeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload
        },
        toggleMusic: (state) => {
            state.isPlay = !state.isPlay
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchMeta.fulfilled, (state, action: PayloadAction<TMeta>) => {
                state.meta = {...action.payload}
                // state.meta = {
                //     ...state.meta,
                //     today: {
                //         ...state.meta.today,
                //         time: state.meta.today.time + 1
                //     }
                // }
            })
    }
})

export const {changeToken, toggleMusic} = appSlice.actions
export default appSlice.reducer
