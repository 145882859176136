import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="35" height="12" viewBox="0 0 35 12" {...props} >
            <path d="M34.5303 6.53033C34.8232 6.23743 34.8232 5.76256 34.5303 5.46967L29.7574 0.696697C29.4645 0.403803 28.9896 0.403803 28.6967 0.696697C28.4038 0.98959 28.4038 1.46446 28.6967 1.75736L32.9393 6L28.6967 10.2426C28.4038 10.5355 28.4038 11.0104 28.6967 11.3033C28.9896 11.5962 29.4645 11.5962 29.7574 11.3033L34.5303 6.53033ZM5.97818e-08 6.75L34 6.75L34 5.25L-5.97818e-08 5.25L5.97818e-08 6.75Z" fill="black"/>
        </Svg>
    );

export default Icon;
