import React  from 'react';
import styled from "styled-components";

import {
  ContainerWhite 
} from './styled'


const Spinner = styled.div`
  margin-top: 30%;
  position: relative;
  width: 54px;
  height: 54px;
  display: inline-block;
  margin-left: 50%;
  margin-right: 50%;
  padding: 10px;
  border-radius: 10px;
  transform: scale(0.7);

  

  div {
    width: 6%;
    height: 16%;
    background: #FFF;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity:  1;
    -webkit-border-radius: 50px;
    -webkit-box-shadow: 0 0 3px rgba(0,0,0,0.2);

  }


  @keyframes fade {
    from {opacity: 1;}
    to {opacity: 0.25;}
  }



  

`

const Bar1 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(0deg) translate(0, -130%);
  -webkit-animation-delay: 0s;
`

const Bar2 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(30deg) translate(0, -130%); 
-webkit-animation-delay: -0.9167s;
`
const Bar3 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(60deg) translate(0, -130%); 
-webkit-animation-delay: -0.833s;
`
const Bar4 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(90deg) translate(0, -130%); 
-webkit-animation-delay: -0.7497s;
`
const Bar5 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(120deg) translate(0, -130%); 
-webkit-animation-delay: -0.667s;
`
const Bar6 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(150deg) translate(0, -130%); 
-webkit-animation-delay: -0.5837s;
`
const Bar7 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(180deg) translate(0, -130%); 
-webkit-animation-delay: -0.5s;
`

const Bar8 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(210deg) translate(0, -130%); 
-webkit-animation-delay: -0.4167s;
`

const Bar9 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(240deg) translate(0, -130%); 
-webkit-animation-delay: -0.333s;
`

const Bar10 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(270deg) translate(0, -130%); 
-webkit-animation-delay: -0.2497s;
`

const Bar11 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(300deg) translate(0, -130%); 
-webkit-animation-delay: -0.167s;
`
const Bar12 = styled.div`
    -webkit-animation: fade 1s linear infinite;
    -webkit-transform:rotate(330deg) translate(0, -130%); 
-webkit-animation-delay: -0.0833s;
`

function Preloader() {
    return (
      <ContainerWhite style={{
          display: 'flex',
          height:'100vh',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          opacity: 0.96,
          position: 'absolute',
          
          }}>
            <Spinner>
              <Bar1  />
              <Bar2 />
              <Bar3  />
              <Bar4  />
              <Bar5/>
              <Bar6/>
              <Bar7/>
              <Bar8/>
              <Bar9/>
              <Bar10/>
              <Bar11/>
              <Bar12/>
          </Spinner>
        </ContainerWhite>
    );
}

export default Preloader;
