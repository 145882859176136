import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import fetchRef from "./fetchRef";

export interface RefState {
    level: number,
    price: string,
    secret: string,
    link: string,
    click:number,
    reg: number,
    ref: number,
    parent: string,
    koef: number,
    team: Array<{name: string, connected: string, totalPods: number, active:any, mypodsCount:Array<{slug: string, count: number}>}>,
    isShowModal: boolean
}

const initialState: RefState = {
    price: '0',
    level: 0,
    click:0,
    reg:0,
    team:[],
    ref:0,
    link: '',
    secret: '',
    koef: 1,
    parent:'',
    isShowModal: false
}

export const refSlice = createSlice({
    name: 'ref',
    initialState,
    reducers: {
        showModal: state => {
            state.isShowModal = true
        },
        closeModal: state => {
            state.isShowModal = false
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchRef.fulfilled, (state, action: PayloadAction<{
                level: number,
                link: string,
                secret: string,
                parent: string,
                koef: number,
                click: number,
                reg: number,
                team: Array<{name: string, connected: string, totalPods: number, active:any, mypodsCount:Array<{slug: string, count: number}>}>,
                ref: number,
                price: string
            }>) => {
                state.level = action.payload.level
                state.link = action.payload.link
                state.click = action.payload.click
                state.reg = action.payload.reg
                state.team = action.payload.team
                state.ref = action.payload.ref
                state.secret = action.payload.secret
                state.price = action.payload.price
                state.koef = action.payload.koef
                state.parent = action.payload.parent
            })
    }
})

export const {showModal, closeModal} = refSlice.actions
export default refSlice.reducer
