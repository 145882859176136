import { useState, useRef } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import i18next from 'i18next';
import BaseHeadphoneModal from './BaseHeadphoneModal';
import { Image, Info, Row, Title, RowNameBuy, Stats, Value, ChargeValue } from '../UserHeadphoneComponent';
import { IDefaultPod } from '../../../store/user/types';
import { ChargeIcon, CoinsIcon, DropDownIcon } from '../../../UI/Svg';
import RepairCardComponent, { RepairCardDescription, RepairCardLink, RepairCardTitle } from '../RepairCardComponent';
import { AppUser } from '../../../api/user';

interface IBuyHeadphoneModalProps extends IDefaultPod {
  onClose: () => void; 
  percentMonth?: any;
  uidUser?:string;
  capacity?:number;
  video?:string;
  showPrice?:boolean;
}
 
const PlusBattery = styled.div`
  position: absolute;
  width:3px;
  height:9px;
  border-radius: 0px 20px 20px 0px;
  background:#55CE3E;
  right:-6px;
  margin-bottom:1px;
`

const ModalValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  small {
    color: #121212;
    font-weight: 400;
    font-size: 12px;
  }
  
`




const UranInput = styled.input`
    font-weight: 500;
    border:0px;
    margin-bottom:10px;
    font-size: 9px;
    color: #000;
    padding-left:10px;
    background: #EFEFEF;
    height:25px;
    border-radius:30px;
`
const CounterPod = styled.div`
display: flex;
flex-direction: row;

gap:10px;
width: 100%;
align-items: center;
justify-content: start;
text-align:center;
margin-bottom:10px;

`
const CCDiv = styled.div`
display: flex;
flex-direction: row;

gap:10px;
align-items: center;
justify-content: start;
text-align:center;
margin-bottom:10px;

span {
    color:black;
    font-size: 25px;
    font-weight:700;
    padding-top:5px;
    margin-left:4px;
    margin-right:4px;
}

`
const InvestButton = styled.div`
cursor:pointer;
font-weight: 500;
font-size: 14px;
color: #fff;
height:55px;
align-items: center;
display:flex;

flex-direction: row;
text-align:center;

background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
padding:10px 20px 8px 20px;
border-radius:15px;
justify-content: space-between;

span {
    font-size:34px;
}
div{
    font-size:16px;
}

`



const HeadImage= styled.div`
    width:20%;
`

const HeaderCard= styled.div`
       color:#000;
       font-size:20px;
       font-weight:700;

      
`

const BlockVideo = styled.div`
    overflow: hidden;
    min-width: 250px;
`
const Circle= styled.div`
    color:#000;
    border-radius:100%;
    text-align:center;
    justify-content:center;
    margin:auto;
    align-items: center;
    display:flex;
    font-size:30px;
    color:#5A5A5A;
    width:35px ;
    height:35px;
    background-color:#D9D9D9

      
`

const  ChooseHeadphones =  styled.div`
    width:100px;
    height:30px;
    border-radius:10px;
    text-align:center;
    justify-content: space-between;
    padding-right:10px;
    align-items: center;
    display: flex;
    font-size:16px;
    font-weight:500;
    span {
            color:black;
        }
`

function BuyHeadphoneModal ({ image,id,capacity,video,showPrice, onClose, name,price,uidUser, percentMonth}: IBuyHeadphoneModalProps) {
  const [showContact, setShowContact] = useState(false);
  const [stopGettingContacts, setStopGettingContacts] = useState(false);
  const [countToBue, SetCountToBue] = useState(1);
  const inputRefContact = useRef<HTMLInputElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);
    const inputRefName = useRef<HTMLInputElement>(null);
    const undent = 'undent'


    const [response, setResponse] = useState("");

    const handleClick = () => {
      setResponse("Заявка отправлена");
    };

  function sendLead () {
    if (inputRefContact.current && inputRefContact.current.value && inputRefName.current && inputRefName.current.value) {
            setShowContact(false)
            setStopGettingContacts(true)
            const Fphone = inputRefContact.current.value;
            const Fname = inputRefName.current.value;
            console.log(Fphone,Fname);
            inputRefContact.current.value = '';
            inputRefName.current.value = '';
            AppUser.leadEdu(Fphone, Fname)
        }
    else   alert('Заполните форму')
}

  return (
    <BaseHeadphoneModal onClose={onClose}>
        <HeaderCard>
             Подробнее о сумке
        </HeaderCard>
        <Info  style={{marginTop:'20px',marginBottom:'30px'}}>
          <Stats>
              <Row >
                  <RowNameBuy>
                    {/* <CoinsIcon height={16} width={12} /> */}
                        {/* <span style={{ whiteSpace: 'pre-line',lineHeight:'19px' }}>
                        Деньги сыпятся с неба всегда и без остановки, вас ограничивает только вместимость сумки.
                        Покупка данную сумку, вы сможете ловить в нее <b style={{color:'#000'}}>до {capacity}D</b> в день и в любой момент выводить свой улов к себе на карту либо на крипто-кошелек.
                        </span> */}
                            <BlockVideo
                                style={{
                                    borderBottomLeftRadius: 10,
                                    borderBottomRightRadius: 10,
                                    borderTopRightRadius: 10,
                                    borderTopLeftRadius: 10,
                                    overflow: 'hidden',
                                }}
                                >
                                <iframe
                                    style={{ borderRadius: 20, overflow: "hidden" }}
                                    height="200px"
                                    src={video}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                />
                                
                            </BlockVideo>
                  </RowNameBuy>
                  {/* <ModalValue>{percentMonth }</ModalValue> */}
              </Row>
              {/* <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>
              
              <Row>
                  <RowNameBuy>
                      <span>Средняя доходность за 3 месяца</span>
                  </RowNameBuy>
                  <ModalValue> 9-15% </ModalValue>
              </Row>
               
              <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>
 
          
              <Row>
                  <RowNameBuy>
                      <span> Продажа </span>
                  </RowNameBuy>
                  <ModalValue>Через 3 месяца</ModalValue>
              </Row>
              <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>
              <Row>


                  <RowNameBuy>
                      <span>Доступно</span>
                  </RowNameBuy>
                  <ModalValue> Без ограничений </ModalValue>
              </Row> */}

          </Stats>
          
      </Info>
      <CounterPod>
                <HeadImage>
                    <Image src={image} />
                </HeadImage>
                <ChooseHeadphones >
                    <span>{name} x {countToBue}</span>
                </ChooseHeadphones>
                <CCDiv>
                    {countToBue>1 ? (
                        <Circle onClick={(event) => {
                            SetCountToBue(countToBue-1)
                        }}>-</Circle>
                    ):(
                        <Circle style={{opacity:0.5}} onClick={(event) => {
                           
                        }}>-</Circle>
                    )}
                    
                    <span>{countToBue}</span>
                    <Circle onClick={(event) => {
                        SetCountToBue(countToBue+1)
                    }}>+</Circle>
                </CCDiv>
                

        </CounterPod>
        {response && (
            <div style={{ display:'flex', justifyContent:'center', alignItems:'center', height:50,backgroundColor: 'rgba(0, 128, 0, 0.5)', padding: '10px', borderRadius: '10px',margin:2, marginTop: '15px',textAlign:'center' }}>
                <div style={{fontSize:'19px'}}>{response}</div>
            </div>
        )}
        {
        showPrice ? (
        <InvestButton onClick={(event) => {
                            window.open(`https://app.marketgame.space/api/wallet/pay-init-card?user=${uidUser}&pod=${id}&count=${countToBue}`)
                        }}>

            <span>{(countToBue*price).toLocaleString('ru')} $</span>
            <div>Инвестировать</div>
        </InvestButton>
            
        ) : (
                response ? null : (
                            <InvestButton style={{display:'flex',justifyContent:'center'}} onClick={() => handleClick() }>
                                <div style={{fontSize:'19px'}}>Начать зарабатывать</div>
                            </InvestButton>
                        )
        
        ) }
      
            

    </BaseHeadphoneModal>
  )
}

export default BuyHeadphoneModal;