import React from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import { Cell } from '../../views/RefViews/styled';

const UpgradeBlockTableRow = styled.div`
  display: flex;
  font-size: 14px;
  height: 52px;
  justify-content: space-between;
  margin-top: 5px;
  color: #DEDEDE;
`

const UpgradeBlockTableRowHuman = styled(Cell)`
  width: 124px;
  background: #2B2B2B;
`
const UpgradeBlockTableRowDay = styled(Cell)`
  width: 91px;
  background: #2B2B2B;
`
const UpgradeBlockTableRowMonth = styled(Cell)`
  width: 93px;
  background: radial-gradient(50% 50% at 50% 50%, #FFCD1B 0%, #FFBB36 100%);
  border-radius: 10px;
  color: #363636;
`
function RowComponent({humans, day, month}: {humans: number, day: string, month: string}) {
    return (

        <UpgradeBlockTableRow>
            <UpgradeBlockTableRowHuman>{humans} {i18next.t('mining.components')}</UpgradeBlockTableRowHuman>
            <UpgradeBlockTableRowDay>{day} $</UpgradeBlockTableRowDay>
            <UpgradeBlockTableRowMonth>≈ {month} $</UpgradeBlockTableRowMonth>
        </UpgradeBlockTableRow>
    );
}

export default RowComponent;
