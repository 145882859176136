import React, {useState,useContext,useEffect} from 'react';
import { useSearchParams, useNavigate } from "react-router-dom";
import WhiteLevelView from "../../views/RefViews/WhiteLevelView";
import BlackLevelView from "../../views/RefViews/BlackLevelView";
import Preloader from "../../views/RefViews/Preloader";
import PreloaderMax from "../../views/RefViews/PreloaderMax";

import {useRefData, useRefState} from "../../store/ref/hooks";
import {QrcodeModal} from "../../components/Modals";


function Index() {
    useRefData()
    const {level} = useRefState()
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    return (
        <div style={{height:'100vh',width:'100vw', overflowY:'scroll'}}>
            <QrcodeModal/>
            {level === 0 ?  <PreloaderMax/> : null}
            {level === 1 ? <BlackLevelView/> : null}
            {level === 1 ?  <WhiteLevelView/> : null}
           
        </div>
    )
}

export default Index;
