import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo,useState} from "react";
import { retrieveLaunchParams,mockTelegramEnv } from '@telegram-apps/sdk';
import {useSearchParams} from "react-router-dom";
import {AppDispatch, RootState} from "../index";
import {changeToken, toggleMusic} from "./appSlice";
import fetchMeta from "./fetchMeta";
import {AppUser} from "../../api/user";

export const getApp = (state:RootState) => state.app

export function useAppAction() {
    const dispatch = useDispatch<AppDispatch>()
    const onChangeToken = useCallback((token: string) => {
        dispatch(changeToken(token))
    }, [dispatch])
    const getMeta = useCallback(() => {
        dispatch(fetchMeta())
    }, [dispatch])
    const onToggleMusic = useCallback(() => {
        dispatch(toggleMusic())
    }, [dispatch])
    return useMemo(() => ({
            onChangeToken,
            getMeta,
        onToggleMusic,
        }),[
        onChangeToken,
        getMeta,
        onToggleMusic,
    ])
}
export function useAppState() {
    return useSelector(getApp)
}

export function useToken() {


    let initData: any;
        try {
        const { initDataRaw, initData: retrievedInitData } = retrieveLaunchParams();
        initData = retrievedInitData;
    } catch (err) {
        console.log(err);
        initData = {
            user: {
            id: 99281932,
            firstName: 'Andrew',
            lastName: 'Rogue',
            username: 'rogue',
            languageCode: 'en',
            isPremium: true,
            allowsWriteToPm: true,
            },
            hash: '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31',
            authDate: new Date(1716922846000),
            startParam: 'debug',
            chatType: 'sender',
            chatInstance: '8428209589180549439',
        };
    }
    
    const [searchParams] = useSearchParams()
    const {onChangeToken} = useAppAction()
    useEffect(() => {
        const token = searchParams.get('token')
        if (token) {
            onChangeToken(token);
        }
        else{
            AppUser.authServer(initData).then(data => {
                window.open(`/game?token=${data.data.token}&start=${initData.startParam}`, '_self');
            });
        }
    }, [])
}

export function useAppData() {
    const {isPlay} = useAppState()
    const {getMeta} = useAppAction()
    

    const getData = useCallback(() => {
        getMeta();
    }, [getMeta])

    useEffect(() => {
        let interval: any
        if (isPlay) {
            interval = setInterval(() => {
                getMeta()
            }, 1000)
        }
        if (interval) {
            return () => clearInterval(interval)
        }
    }, [getData, isPlay])
}

