import React, {ReactNode} from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  padding-left: 15px;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-right: 16px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: rgba(0, 0, 0, 0.84);
`
function BlockComponent({title, children, icon, ...props}: {title: string, children: ReactNode, icon?: ReactNode}) {
    return (
        <Wrapper {...props}>
            <Title>{title} {icon}</Title>
            {children}
        </Wrapper>
    );
}

export default BlockComponent;
