import React, {ReactNode, useCallback, useEffect, useRef} from 'react';
import styled from "styled-components";
import {CloseIcon} from "../Svg";

interface ModalProps {
    children: ReactNode,
    title: string,
    onClose: () => void,
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 100000;
  padding: 0;
  background: rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(22px);
`

const ModalBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 64px 40px 35px;
  box-sizing: border-box;
  background: #fff;
  backdrop-filter: blur(22px);
  max-height: 80%;
  overflow: auto;
`

const CloseButton = styled.div`
  padding: 4px;
  position: absolute;
  top: 20px;
  left: 20px;
`

const ModalTitle = styled.h4`
  font-weight: bold;
  margin-bottom: 16px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
  font-size: 28px;
  line-height: 27px;
  color: #000;
`

const Content = styled.div`
  width: 100%;
  margin-top: 39px;
`


function Modal({children, title, onClose}: ModalProps) {
    const wrapper = useRef<HTMLDivElement | null>(null);

    const onDismissHandler = useCallback ((e: any) => {
        if (wrapper.current === e.target) onClose()
    }, [onClose])

    useEffect(() => {
        const element = wrapper.current
        if (element) {
            element.addEventListener('click', onDismissHandler, false);
            return () => {
                element.removeEventListener('click', onDismissHandler, false);
            };
        }
    }, [onDismissHandler, wrapper]);
    return (
        <ModalWrapper  ref={wrapper}>
            <ModalBlock >
                <CloseButton onClick={onClose}>
                    <CloseIcon width={16} height={16}/>
                </CloseButton>
                <ModalTitle>
                    {title}
                </ModalTitle>
                <Content>
                    {children}
                </Content>
            </ModalBlock>
        </ModalWrapper>
    );
}

export default Modal;
