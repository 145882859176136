import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="16" height="24" viewBox="0 0 16 24" {...props} >
            <path d="M9.29506 10.3815C9.22646 10.2091 9.05454 10.0952 8.86279 10.0952L7.06549 10.0952L7.47131 6.57706L7.47152 6.57706C7.49259 6.39333 7.39341 6.21597 7.22253 6.13137C7.05164 6.04697 6.8444 6.07305 6.70161 6.19686L2.07307 10.2099C1.93091 10.3333 1.88214 10.5286 1.95074 10.701C2.01955 10.8732 2.19126 10.987 2.38281 10.987L4.18011 10.987L3.77429 14.5052L3.77408 14.5052C3.753 14.6889 3.85219 14.8663 4.02307 14.9509C4.19396 15.0353 4.4012 15.0092 4.54399 14.8854L9.17253 10.8724C9.3149 10.7489 9.36367 10.5539 9.29506 10.3815ZM4.82849 13.4394L5.15704 10.5907L5.15724 10.5907C5.17171 10.4645 5.12997 10.3383 5.04215 10.2438C4.95433 10.1492 4.8287 10.0953 4.69708 10.0953L3.58893 10.0953L6.41688 7.64285L6.08834 10.4916C6.07388 10.6178 6.11562 10.744 6.20344 10.8385C6.29126 10.9331 6.41689 10.987 6.54851 10.987L7.65666 10.987L4.82849 13.4394Z" fill="#000"/>
            <path d="M10.7523 13.6219L10.7523 2.80035C10.7514 2.48228 10.6297 2.1776 10.4139 1.95274C10.1981 1.72789 9.9056 1.60109 9.60028 1.6002L7.29621 1.6002L7.29621 1.20015C7.29535 0.882074 7.17363 0.577395 6.9578 0.352544C6.74196 0.127692 6.4495 0.000891569 6.14418 2.49387e-08L4.60813 1.8704e-08C4.30281 0.000892978 4.01034 0.127696 3.79451 0.352544C3.57867 0.577395 3.45696 0.882074 3.4561 1.20015L3.4561 1.6002L1.15203 1.6002C0.846709 1.60109 0.554245 1.7279 0.338409 1.95274C0.122572 2.1776 0.000855806 2.48228 1.04006e-08 2.80035L7.87478e-08 21.2027C0.000857238 21.5208 0.122577 21.8255 0.338409 22.0503C0.554245 22.2752 0.846709 22.402 1.15203 22.4029L6.77396 22.4029C7.91072 23.6361 9.55701 24.2078 11.1767 23.9318C12.7964 23.6558 14.1823 22.5675 14.8827 21.0212C15.5832 19.4752 15.509 17.6689 14.6839 16.1909C13.8588 14.7129 12.3887 13.7522 10.7522 13.6218L10.7523 13.6219ZM4.22411 1.20014C4.22411 0.979225 4.39606 0.800094 4.60812 0.800094L6.14417 0.800094C6.246 0.800094 6.34372 0.842242 6.41572 0.917251C6.48772 0.992261 6.52818 1.09406 6.52818 1.20014L6.52818 1.6002L4.22411 1.6002L4.22411 1.20014ZM1.15202 21.6028C0.939955 21.6028 0.768006 21.4236 0.768006 21.2027L0.768006 2.80035C0.768006 2.57943 0.939955 2.4003 1.15202 2.4003L9.60027 2.4003C9.7021 2.4003 9.79982 2.44245 9.87182 2.51746C9.94382 2.59247 9.98428 2.69427 9.98428 2.80035L9.98428 13.6219C8.23376 13.7588 6.68178 14.8456 5.89884 16.4824C5.11607 18.1192 5.21789 20.0645 6.16713 21.603L1.15202 21.6028ZM10.3683 23.203C9.24799 23.203 8.17359 22.7393 7.38139 21.9141C6.58918 21.0888 6.14417 19.9695 6.14417 18.8024C6.14417 17.6353 6.58923 16.516 7.38139 15.6907C8.17359 14.8654 9.24795 14.4018 10.3683 14.4018C11.4886 14.4018 12.563 14.8655 13.3552 15.6907C14.1474 16.516 14.5924 17.6353 14.5924 18.8024C14.5917 19.9693 14.1465 21.0882 13.3545 21.9133C12.5625 22.7385 11.4884 23.2022 10.3683 23.203Z" fill="#000"/>
            <path d="M12.5894 18.4342L12.5894 16.233C12.5894 15.9985 12.3684 15.8083 12.0957 15.8083C11.8231 15.8083 11.6021 15.9985 11.6021 16.233L11.6021 18.4342C11.184 18.5627 10.855 18.8457 10.7055 19.2053L9.13369 19.2053C8.86107 19.2053 8.64001 19.3955 8.64001 19.63C8.64001 19.8644 8.86107 20.0546 9.13369 20.0546L10.7055 20.0546C10.8739 20.4667 11.2765 20.7755 11.7717 20.8725C12.2669 20.9694 12.7853 20.8407 13.1443 20.5319C13.5033 20.2231 13.653 19.7772 13.5403 19.3513C13.4275 18.9253 13.0685 18.579 12.5894 18.4342L12.5894 18.4342ZM12.0957 20.0546C11.8961 20.0546 11.716 19.9511 11.6398 19.7924C11.5633 19.6338 11.6054 19.4512 11.7466 19.3297C11.8879 19.2082 12.1001 19.172 12.2846 19.2378C12.4691 19.3033 12.5894 19.4582 12.5894 19.63C12.5894 19.7426 12.5374 19.8506 12.4448 19.9302C12.3523 20.0098 12.2267 20.0546 12.0957 20.0546Z" fill="#000"/>
        </Svg>
    );

export default Icon;
