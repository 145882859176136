import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ReactNode } from 'react'
import { CloseIcon } from '../../../UI/Svg'

interface IBaseHeadphoneModalProps {
  children: ReactNode;
  onClose: () => void;
}

const ModalBackdrop = styled(motion.div)`
  position: fixed;
  inset: 0;
  background: rgba(14, 14, 14, 0.4);
  backdrop-filter: blur(22px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index:10;
`

const Modal = styled(motion.div)`
  position: relative;
  z-index: 200000;
  color: #000;
  min-height: 0;
  overflow-y: auto;
  max-height: calc(100vh - 90px);
  background-color: white;
  width: calc(100% - 40px);
  max-width: 340px;
  border-radius: 30px;
  color: #000;
  padding: 40px 20px 20px 20px;
`

const ModalClose = styled(motion.button)`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
`

function BaseHeadphoneModal ({children, onClose}: IBaseHeadphoneModalProps) {
  return (
    <ModalBackdrop 
      initial={{opacity: 0}} 
      animate={{opacity: 1}} 
      exit={{opacity: 0}} 
      onClick={onClose}
    >
      <Modal 
        style={{overflow: 'scroll'}}
        initial={{ y: -10 }} 
        animate={{ y: 0 }} 
        exit={{ y: -10 }} 
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      <ModalClose onClick={onClose}>
        <CloseIcon width={18} height={18}/>
      </ModalClose>
      </Modal >
    </ModalBackdrop>
  )
}

export default BaseHeadphoneModal;