import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import {Modal} from "../../UI/Modal";
import {useRefAction, useRefState} from "../../store/ref/hooks";

function QrcodeModal() {
    const {isShowModal, link} = useRefState()
    const {onCloseModal} = useRefAction()
    if (!isShowModal) return null
    return (
        <Modal title='Ref link' onClose={onCloseModal}>
            <QRCodeSVG value={link} />
        </Modal>
    );
}

export default QrcodeModal;
