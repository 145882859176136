import axios, { AxiosInstance, AxiosResponse } from "axios";

export const serverUrl = 'https://app.marketgame.space'
const apiUrl = `${serverUrl}/api`

const axiosInstance: AxiosInstance = axios.create({
    baseURL: apiUrl,
});

export const handle = (promise: Promise<AxiosResponse<any>>): any => promise
        .then(data => ([data.data, undefined]))
        .catch(error => Promise.resolve([undefined, error?.response?.data || error?.response || error]))

export {axiosInstance, apiUrl}
