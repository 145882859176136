import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import userimage from '../../assets/images/uranlife.png';
import ticket from '../../assets/images/ticket.png';
import {useUserState} from "../../store/user/hooks";
import {ReferralIcon,ChatIcon} from "../../UI/Svg";
import {useAppState} from "../../store/app/hooks";
import StoriesListComponent from './StoriesListComponent';

const Wrapper = styled.div`
  width: 100%;
  padding: 63px 27px 16px 15px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
`
const Left = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  gap: 15px;
`

const Logo = styled.div`
  width: 40px;
  display: flex;
  align-content: center;
  align-items: center;
  overflow: hidden;
  justify-content: center;
`

const Baner = styled.div`
  margin-left: 20px;
  img {
    max-height: 40px;
  }
`
const GradientText = styled.span`
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`
const Email = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 13px;
  align-items: center;
  letter-spacing: 0.01em;
  margin-bottom:10px;
  color: #000;
`

// const ButtonExit = styled.button`
//   display: flex;
//   align-items: center;
//   align-content: center;
//   justify-content: center;
//   border: none;
//   background: none;
//   outline: none;
// `
const ButtonShare = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #fff;
  width: 50px;
  height: 50px;
  outline: none;
`

const ButtonChat = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: right;
  border-radius: 50%;
  border: none;
  background: #fff;
  width: 50px;
  height: 50px;
  outline: none;
`

function HeaderComponent({setLoading}:any) {
    const {token} = useAppState()
    const {user} = useUserState()
    console.log(user?.ads_baner);
    const loader = () => {
      setLoading(true)
    }
    return (
        <Wrapper>
            <Left>
              {/* <StoriesListComponent /> */}
                {user?.ads_baner.link && (
                  <Baner>
                    <Link to={user?.ads_baner.link ?? ''}>
                      <img src={user?.ads_baner.img} alt="" />
                    </Link>
                  </Baner>
                )}

                {/* <Logo >
                    <img src={userimage} alt=""/>
                </Logo> */}
              {/* <img style={{height:48}} src={ticket} alt=""/> */}
                {/* <ButtonExit>
                    <ExitIcon/>
                </ButtonExit> */}
                {/* <Email>
                  Скоро <br/>
                  <GradientText>Uran Stories</GradientText>
                </Email> */}
            </Left>
            <Right style={{height:20}}>
             {/* <ButtonChat onClick={loader} to={`https://chat.uranmusic.com/?token=${token}&mobile=true`}><ChatIcon width={32} height={32}/></ButtonChat>
             <ButtonShare onClick={loader}  to={`/ref?token=${token}&web=true`}><ReferralIcon width={32} height={32}/></ButtonShare> */}
            </Right>
        </Wrapper>
    );
}

export default HeaderComponent;
