import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AppUser} from "../../api/user";
import {IUser} from "./types";

const fetchUser = createAsyncThunk<IUser | null,
    void,
    {
        state: RootState
    }>(
    'user/fetchUser',
    async (_, ThunkAPI) => {
        const {token} = ThunkAPI.getState().app
        if (token) {
            const [userRes, userErr] = await handle(AppUser.getUserDetailWeb(token))
            if (userRes) {
                return userRes.data
            }
            if (userErr) {
                console.log(userErr)
            }
        }
        return null
    }
)

export default fetchUser
