import { createGlobalStyle } from 'styled-components';

const ResetCSS = createGlobalStyle`
  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: middle;
  }
  /* HTML5 display-role reset for older browsers */
  /* prettier-ignore */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
    font-size: 16px;
  }
  ol,
  ul {
    list-style: none;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  [role="button"] {
    cursor: pointer;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  button {
    border: none;
    outline: none;
  }
    :root {
    --tg-theme-subtitle-text-color: #ffffff;
    --tg-theme-button-color: #007aff;
    --tg-theme-hint-color: #ffffff;
    --tg-theme-secondary-bg-color: #1c1c1c;
    --tg-theme-link-color: #007aff;
    --tg-theme-section-header-text-color: #e5e5e5;
    --tg-theme-button-text-color: #ffffff;
    --tg-theme-destructive-text-color: #ff453a;
    --tg-theme-accent-text-color: #007aff;
    --tg-color-scheme: dark;
    --tg-theme-bg-color: #282828;
    --tg-theme-bottom-bar-bg-color: #3e464c;
    --tg-theme-text-color: #ffffff;
    --tg-theme-header-bg-color: #1c1c1c;
    --tg-theme-section-bg-color: #282828;
    --tg-theme-section-separator-color: #3d3d3d;
    --tg-viewport-height: 100vh;
    --tg-viewport-stable-height: 100vh;
    --tg-content-safe-area-inset-top: 0px;
    --tg-content-safe-area-inset-bottom: 0px;
    --tg-content-safe-area-inset-left: 0px;
    --tg-content-safe-area-inset-right: 0px;
  }
    

`;

export default ResetCSS;
