import React, {Suspense, useRef} from 'react';
import i18next from 'i18next';
import Slider, { CustomArrowProps } from "react-slick";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import styled from "styled-components";
import {Link, useSearchParams} from "react-router-dom";
import {Canvas} from "@react-three/fiber";
import {ArrowIcon} from "../../UI/Svg";
import {useAppState} from "../../store/app/hooks";


import {
    CodeBlock,
    CodeBlockBarcode,
    CodeBlockCode,
    CodeBlockCodeKey,
    StatBlock,
    Container,
    LabelBlack,
    LabelRainbow,
    Level,
    Photo,
    Product,
    ProductDataPhoto,
    ProductInfo,
    ProductInfoData, ProductInfoDataBtn,
    ProductInfoDataRow, ProductInfoDataRowBattery,
    ProductInfoDataRowModel,
    ProductInfoDataRowNumber, ProductInfoDataRowPrice, ProductInfoDataRowText,
    ProductName,
    ProductText,
    RainbowCircle,
    Statistics,
    StatisticsItem,
    SliderFull,
    Slide,
    SliderTitle,
    SliderArrows
} from './styled'
import photo1 from '../../assets/images/photo-1.png'
import photo from '../../assets/images/photo.png'
import arrowRightBlack from '../../assets/images/arrow-right-black.svg'
import arrowLeftBlack from '../../assets/images/arrow-left-black.svg'
import labelBlack from '../../assets/images/labelBlack.png'
import barcode from '../../assets/images/barcode.svg'
import net from '../../assets/images/net.svg'
import earn from '../../assets/images/img_earnings.svg'
import bat from '../../assets/images/img_bat.svg'
import {useRefAction, useRefState} from "../../store/ref/hooks";

const HeadphoneModelComponent = React.lazy(() => import('../../components/RefComponents/HeadphoneModelComponent'));

const ButtonShare = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #FAFAFA;
  width: 53px;
  height: 53px;
  outline: none;
`
const CanvasWrapper = styled.div`
  height: 328px;
  width: 100%;
`

function WhiteLevelView() {
    const {link, level, click, reg, ref,secret,parent} = useRefState()
    const {onShowModal} = useRefAction()
    const {token} = useAppState()
    const [searchParams] = useSearchParams()
    const fromWeb = searchParams.get('web')

    const sliderRef = React.useRef<Slider>(null);

    const settings = {
      arrows: false,
      dots: false,
      infinite: false,
      speed: 500,
    };
    
    return (

        <Container>
            <Level>
                <div style={{display:'flex',width:'100%'}}>
                {
                    fromWeb ? (
                        <ButtonShare style={{transform: 'rotate(180deg)'}} to={`/edu?token=${token}`}><ArrowIcon
                            width={32} height={32}/></ButtonShare>
                    ) : (null)
                }
                </div>
                <div>
                    <LabelBlack>{i18next.t('whitelevel.get')} <img alt="" /> {i18next.t('whitelevel.uniq')} </LabelBlack>
                    <LabelRainbow>{i18next.t('whitelevel.one_thousand')}</LabelRainbow>
                </div>
                <Photo>
                    <img src={photo} alt=""/>
                    {/* <Suspense fallback={<>
                        <img src={photo1} alt=""/>
                        <RainbowCircle/>
                    </>}>
                        <CanvasWrapper>
                            <Canvas>
                                <HeadphoneModelComponent objName='Gradient'/>
                            </Canvas>
                        </CanvasWrapper>
                    </Suspense> */}
                </Photo>
                <CodeBlock >
                   
                    <CopyToClipboard onCopy={() => window.alert('Скопировано 👌')} text={link}>
                        <CodeBlockCode  style={{width:'80%'}}>
                            <CodeBlockCodeKey>{link.length > 24 ? `${link.slice(30,40)}...` : ''}</CodeBlockCodeKey>
                            <img style={{marginLeft:10}} src={net} alt=""/>
                        </CodeBlockCode>
                    </CopyToClipboard>
                     <CodeBlockBarcode onClick={onShowModal}>
                        <img src={barcode} alt=""/>
                    </CodeBlockBarcode>
                </CodeBlock>

                <ProductText style={{color:'gray',fontSize:'12px', lineHeight:1, fontStyle: 'italic', maxWidth: 230, margin: '-35px auto'}}>
                {i18next.t('whitelevel.individual_link')}
                </ProductText>
                <CodeBlock >
                <CopyToClipboard  onCopy={() => window.alert(`${i18next.t('whitelevel.copy')}`)} text={`secret ${secret}`}>
                    <CodeBlockCode  style={{width:'100%'}}>
                        <CodeBlockCodeKey style={{fontSize: 22}}>{link.length > 24 ? `secret ${secret}` : ''}</CodeBlockCodeKey>
                        <img style={{marginLeft:10}} src={net} alt=""/>
                    </CodeBlockCode>
                </CopyToClipboard>
                </CodeBlock>
                
                <ProductText style={{color:'gray',fontSize:'12px', lineHeight:1, fontStyle: 'italic', maxWidth: 230, margin: '-35px auto 0 auto'}}>
                {i18next.t('whitelevel.how_get')}
                </ProductText>

                <SliderFull >
                    <SliderTitle><span>{i18next.t('whitelevel.advantage')}</span> {i18next.t('whitelevel.author')}</SliderTitle>
                    <Slider {...settings} ref={sliderRef} > 
                        <Slide>
                            <p>{i18next.t('whitelevel.secret_phrase')}</p>
                        </Slide>
                        <Slide>
                            <p>{i18next.t('whitelevel.author_desc')} <span>{i18next.t('whitelevel.endlessly')} </span> {i18next.t('whitelevel.upgrade_r')} </p>
                        </Slide>
                        <Slide>
                            <p>{i18next.t('whitelevel.entrance_closechannel')} <span>«Uran Family»</span></p>
                        </Slide>
                        <Slide>
                            <p style={{alignItems:'center'}}>{i18next.t('whitelevel.free_party')} <b>Uran Music</b></p>
                        </Slide>
                        <Slide>
                            <p>{i18next.t('whitelevel.first_access')} <b>Uran Music</b></p>
                        </Slide>
                        
                    </Slider>
                    <SliderArrows>
                        <div onClickCapture={()=>sliderRef.current?.slickPrev()}><img src={arrowLeftBlack} alt="" /></div>
                        <div onClickCapture={()=>sliderRef.current?.slickNext()}><img src={arrowRightBlack} alt="" /></div>
                    </SliderArrows>
                </SliderFull>
               


                <Statistics>
                    <h3>{i18next.t('blacklevel.statistic')}</h3>
                    <StatisticsItem style={{borderBottom: '1px solid #E9E9E9'}}>
                        <span>{i18next.t('blacklevel.link_page')}</span>
                        <i>{click} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    <StatisticsItem style={{borderBottom: '1px solid #E9E9E9'}}> 
                        <span>{i18next.t('blacklevel.registration')}</span>
                        <i>{reg} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    <StatisticsItem style={{borderBottom: '1px solid #E9E9E9'}}>
                        <span>{i18next.t('blacklevel.user')}</span>
                        <i>{ref} {i18next.t('blacklevel.people')}</i>
                    </StatisticsItem>
                    
                    <StatisticsItem style={{borderBottom: '1px solid #E9E9E9'}}>
                        <span>{i18next.t('blacklevel.ref')}</span>
                        <i>{parent ? (<i> {parent} </i>) : (<i> {i18next.t('blacklevel.no_ref')} </i>)}</i>
                    </StatisticsItem>


                    
                </Statistics>

                <Product>

                    <ProductText style={{fontWeight: 500, fontSize: 16, color: '#000000', lineHeight: '20px',  maxWidth: 280, margin: '0 auto'}}>
                    {i18next.t('whitelevel.get_r')}
                    </ProductText>

                    <ProductInfo>
                        <ProductDataPhoto>
                            <img src={photo1} alt=""/>
                        </ProductDataPhoto>
                        <ProductInfoData>
                            <ProductInfoDataRow>
                                <ProductInfoDataRowModel>{i18next.t('whitelevel.model')} </ProductInfoDataRowModel>
                                <ProductInfoDataRowNumber># 4840</ProductInfoDataRowNumber>
                            </ProductInfoDataRow>
                            <ProductInfoDataRow>
                                <img style={{width: 19, height: 10}} src={earn} alt=""/>
                                <ProductInfoDataRowText>{i18next.t('whitelevel.earn')}</ProductInfoDataRowText>
                                <ProductInfoDataRowPrice>1267 USDT</ProductInfoDataRowPrice>
                            </ProductInfoDataRow>
                            <ProductInfoDataRow style={{marginTop: 7}}>
                                <img style={{width: 12, height: 18}} src={bat} alt=""/>
                                <ProductInfoDataRowText>{i18next.t('whitelevel.time')}</ProductInfoDataRowText>
                                <ProductInfoDataRowBattery>{i18next.t('whitelevel.time_to_day')}</ProductInfoDataRowBattery>
                            </ProductInfoDataRow>
                            <ProductInfoDataBtn>{i18next.t('whitelevel.no_buy')}</ProductInfoDataBtn>
                        </ProductInfoData>
                    </ProductInfo>
                </Product>
            </Level>
        </Container>
    );
}

export default WhiteLevelView;
