import { ReactNode } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border-radius: 30px;
  padding: 1px;
  width: 230px;
  flex-shrink: 0;
`

const Bg = styled.div`
  background: #fff;
  height: 100%;
  border-radius: 30px;
  width: 100%;
  padding: 16px;
`

export const RepairCardTitle = styled.h3`
  font-weight: 500;
  font-size: 14px;
  color: #2E2E2E;
  margin-bottom: 8px;
  `
  
export const RepairCardDescription = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #919191;
  margin-bottom: 16px;
`

export const RepairCardLink = styled.div`
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  position:absolute;
  border-radius: 0px 0px 30px 30px;
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
  display: flex;
  width:100%;
  align-items: center;
  height: 34px;
  bottom:0px;
  left:0;
  cursor:pointer;
`

function RepairCardComponent ({children}: {children: ReactNode}) {
  return (
    <Wrapper style={{marginTop:'20px',position:'relative', width:'100%'}}>
      <Bg>
        {children}
      </Bg>
    </Wrapper>
  )
}

export default RepairCardComponent;