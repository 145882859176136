import {createGlobalStyle} from 'styled-components';
import './_fonts.scss';

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'HelveticaNeue', sans-serif;
    color: #fff;
  }

  html {
  }

  body {

    img {
      height: auto;
      max-width: 100%;
    }
  }

  #root {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }
  .slick-initialized .slick-slide {
    display: flex;
    height: unset;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    & > div {
      height: 100%;
    }
  }
  .mainText {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, rgba(255, 255, 255, 0.5332) 100%) !important;
    backdrop-filter: blur(2px);
    border-radius: 50px;
    border: none;
    height: 74px;
    width: 100%;
    max-width: 320px;
  }
  .custom-overlay {
    border-radius: 50px;
    min-width: 74px;
    background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%) !important;
    & > div {
      min-width: 74px;
    }
  }
  .custom-caret-list {
    width: 74px !important;
    //background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%) !important;
    background: transparent !important;
    display: flex;
    gap: 12px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 10px 21px 10px 8px;
    max-width: 74px!important;
  }
`;

export default GlobalStyle;
