import React  from 'react';
import i18next from 'i18next';
import styled from "styled-components";

import {
    ContainerWhite 
} from './styled'


const GradientText = styled.span`
        background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;

`

const TextAnimation = styled.div`
    color: #fff;
    background: linear-gradient(to right, #fff 0, #4d4d4d 20%, #fff 30%);
    background-position: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation-fill-mode: forwards;
    -webkit-text-size-adjust: none;
    text-decoration: none;
` 

function Preloader() {
    return (
        <ContainerWhite style={{
            display: 'flex',
            height:'100vh',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            opacity: 0.96,
            position: 'absolute',
            
            }}>
       
            <TextAnimation style={{
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '70%',
                lineHeight: '1.3',
            }}>
                <span  style={{paddingBottom:15}}>{i18next.t('preloader.load')}</span>
                <br />

                <GradientText>Партия Деньги</GradientText> {i18next.t('preloader.desc')}

            </TextAnimation>
        </ContainerWhite>
    );
}

export default Preloader;
