import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "../index";
import {handle} from "../../api";
import {AppApi} from "../../api/app";
import {TMeta} from "./types";
import {initMeta} from "./appSlice";

const fetchMeta = createAsyncThunk<TMeta,
    void,
    {
        state: RootState
    }>(
    'app/fetchMeta',
    async (_, ThunkAPI) => {
        const {token} = ThunkAPI.getState().app
        const data = {
            revenue: "1"
        }
        const [metaRes, metaErr] = await handle(AppApi.getMeta(JSON.stringify(data), token))
        if (metaRes) {
            return metaRes
        }
        if (metaErr) {
            return initMeta
        }
        // return initMeta
    }
)

export default fetchMeta
