/* eslint-disable no-use-before-define */
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import { useSearchParams } from 'react-router-dom';
import BannerModal from "./Modals/BannerModal";
import { IBanner } from '../../store/user/types';
import { AppUser } from '../../api/user';

function BannersComponent ({ banners }: { banners: IBanner[] }) {
  const [searchParams] = useSearchParams();
  const [isShowing, setIsShowing] = useState(false);
  const [currentBannerIndex, setCurrentBannerIndex] = useState<number>(0);

  useEffect(()=> {
      if (!isShowing && banners.length > 0) {
          setCurrentBannerIndex(0);
          setIsShowing(true);
      }
  },  [banners]);

    async function onClose () {
      if (currentBannerIndex < banners.length - 1) {
          await readBanner(banners[currentBannerIndex].id);
          setCurrentBannerIndex(currentBannerIndex + 1);
          return;
      }

      setIsShowing(false);
  }

  function readBanner (id: number) {
    const token = searchParams.get('token');
    return token && AppUser.readBanner(id, token);
  }

  return (
    <>
      {createPortal(<AnimatePresence>
          {isShowing
              ? (<BannerModal banner={banners[currentBannerIndex]} onClose={() => onClose()} />) 
              : null
          }
      </AnimatePresence>, document.body)}
    </>
  )
}

export default BannersComponent;