import { useState, useRef } from 'react';
import styled from 'styled-components';
import i18next from 'i18next';
import BaseHeadphoneModal from './BaseHeadphoneModal';
import { Image, Info, Row, Title, RowName, Stats, Value, ChargeValue } from '../UserHeadphoneComponent';
import { IUserPod } from '../../../store/user/types';
import { ChargeIcon, CoinsIcon, DropDownIcon } from '../../../UI/Svg';
import RepairCardComponent, { RepairCardDescription, RepairCardLink, RepairCardTitle } from '../RepairCardComponent';
import { AppUser } from '../../../api/user';

interface ISmallHeadphoneModalProps extends IUserPod {
  onClose: () => void;
}
 
const PlusBattery = styled.div`
  position: absolute;
  width:3px;
  height:9px;
  border-radius: 0px 20px 20px 0px;
  background:#55CE3E;
  right:-6px;
  margin-bottom:1px;
`

const ModalValue = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #121212;
  small {
    color: #121212;
    font-weight: 400;
    font-size: 12px;
  }
  
`




const UranInput = styled.input`
    font-weight: 500;
    border:0px;
    margin-bottom:10px;
    font-size: 9px;
    color: #000;
    padding-left:10px;
    background: #EFEFEF;
    height:25px;
    border-radius:30px;
`
const GreenBadge = styled.div`
font-weight: 500;
font-size: 9px;
color: #fff;
background: #55CE3E;
padding:5px 10px 4px 10px;
border-radius:30px;

`

const BadgeYellow = styled.div`
font-weight: 500;
font-size: 14px;
color: #323232;
background: radial-gradient(50% 50% at 50% 50%, #FFCD1B 0%, #FFBB36 100%);
padding:10px 20px 8px 20px;
border-radius:30px;

`


const List = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: auto;
  margin-top: 10px;
  padding-bottom:130px;
`


const HeadImage= styled.div`
    width:30%;
    margin-top:-20px;
    margin-left:-2px;
`

const HeaderCard= styled.div`
        display: flex;
        flex-direction: row;
        gap:10px;
        width: 100%;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;

      
`

const  ChooseHeadphones =  styled.div`
    border 2px solid black;
    width:100px;
    height:30px;
    border-radius:10px;
    text-align:center;
    justify-content: space-between;
    padding-left:10px;
    padding-right:10px;
    align-items: center;
    display: flex;
    span {
            color:black;
        }
`

function SmallHeadphoneModal ({ image, onClose, name,total, value,revenue, charge,old, withdrawed,overalvalue, passiveMiningRevenue,activeMiningRevenue, activeMiningRevenueYestarday, passiveMiningRevenueYestarday}: ISmallHeadphoneModalProps) {
  const [showContact, setShowContact] = useState(false);
  const [stopGettingContacts, setStopGettingContacts] = useState(false);
  const inputRefContact = useRef<HTMLInputElement>(null);
  const inputRefName = useRef<HTMLInputElement>(null);

  function sendLead () {
    if (inputRefContact.current && inputRefContact.current.value && inputRefName.current && inputRefName.current.value) {
            setShowContact(false)
            setStopGettingContacts(true)
            const Fphone = inputRefContact.current.value;
            const Fname = inputRefName.current.value;
            console.log(Fphone,Fname);
            inputRefContact.current.value = '';
            inputRefName.current.value = '';
            AppUser.leadEdu(Fphone, Fname)
        }
    else   alert('Заполните форму')
}

  return (
    <BaseHeadphoneModal onClose={onClose} >
        <HeaderCard>
                <HeadImage>
                    <Image src={image} />
                </HeadImage>
                <ChooseHeadphones style={{opacity:'0.2'}}>
                    <span>{name} #1</span>
                    <DropDownIcon/>
                </ChooseHeadphones>
                <ChargeValue 
                      style={{position:'relative', height:'30px', width:'60px'}} charge={charge}>{charge}%
                        <PlusBattery/>
                    </ChargeValue>

        </HeaderCard>
        
        <Info>

          <Stats>
{/*                
              <Row>
                  <RowName>
                      <span>{i18next.t('small_headphone.sum_headphone')} </span>
                  </RowName>
                  <ModalValue>{total}</ModalValue>
              </Row> */}
              <Row>
                  <RowName>
                      <span style={{color:'#E8E8E8'}}>Доступно к продаже через 72 дня</span>
                  </RowName>
              </Row>
              <Row>
                  <RowName>
                      {/* <CoinsIcon height={16} width={12} /> */}
                      <span>{i18next.t('small_headphone.amount')} </span>
                  </RowName>
                  <ModalValue>{value ? Math.round(value).toLocaleString('ru') : 0} $  </ModalValue>
              </Row>
              <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>
              <Row>
                  <RowName>
                      <span style={{color:'#E8E8E8'}}>За все время</span>
                  </RowName>
              </Row>
              <Row>
                  <RowName>
                    {/* <CoinsIcon height={16} width={12} /> */}
                      <span>{i18next.t('small_headphone.profit_active_mining')}</span>
                  </RowName>
                  <ModalValue>{activeMiningRevenue?.toLocaleString('ru')} $</ModalValue>
              </Row>
              <Row>
                  <RowName>
                    {/* <CoinsIcon height={16} width={12} /> */}
                      <span>{i18next.t('small_headphone.profit_passive_mining')}</span>
                  </RowName>
                  <ModalValue>{passiveMiningRevenue?.toLocaleString('ru')} $</ModalValue>
              </Row>
              <Row>
                  <RowName >
                        
                      <span style={{color:'rgba(0,0,0,0)'}}>{i18next.t('small_headphone.withdrawn')}</span>
                  </RowName>
                  {passiveMiningRevenue && activeMiningRevenue ? (
                    <ModalValue><small>Итого</small> {passiveMiningRevenue+activeMiningRevenue} $ </ModalValue>
                  ): (
                    <ModalValue><small>Итого</small> 0,00 $ </ModalValue>
                  )}
              </Row>

              <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>
              <Row>
                  <RowName>
                      <span style={{color:'#E8E8E8'}}>За вчера</span>
                  </RowName>
              </Row>
              <Row>
                  <RowName>
                    {/* <CoinsIcon height={16} width={12} /> */}
                      <span>{i18next.t('small_headphone.profit_active_mining')}</span>
                  </RowName>
                  <ModalValue>{activeMiningRevenueYestarday?.toLocaleString('ru')} $</ModalValue>
              </Row>
              <Row>


                  <RowName>
                    {/* <CoinsIcon height={16} width={12} /> */}
                      <span>{i18next.t('small_headphone.profit_passive_mining')}</span>
                  </RowName>
                  <ModalValue>{passiveMiningRevenueYestarday?.toLocaleString('ru')} $</ModalValue>
              </Row>
              <Row>
                  <RowName >
                        
                      <span style={{color:'rgba(0,0,0,0)'}}>{i18next.t('small_headphone.withdrawn')}</span>
                  </RowName>
                  {passiveMiningRevenueYestarday && activeMiningRevenueYestarday ? (
                    <ModalValue><small>Итого</small> {passiveMiningRevenueYestarday+passiveMiningRevenueYestarday} $ </ModalValue>
                  ): (
                    <ModalValue><small>Итого</small> 0,00 $ </ModalValue>
                  )}
                  
              </Row>

               <hr style={{border:'1px solid #E9E9E9',width:'100%'}}/>

               <BadgeYellow>
                     Новое предложение
              </BadgeYellow>

          </Stats>
      </Info>

      {/* <List> */}
        <RepairCardComponent >
            {!showContact? (
                <div>
                <RepairCardTitle>Увеличить доходность  <br/>от 50 000 Р до 1 000 000 руб</RepairCardTitle>
                <RepairCardDescription>Присоединяйся в команду Uran Music Capital, проходи обучение арбитражу валюты и зарабатывай сверх-прибыль. </RepairCardDescription>
                <Info style={{marginBottom:'30px'}}>
                    <Stats>
                        <Row>
                            <RowName>
                                <span>Затрата времени</span>
                            </RowName>
                            <GreenBadge>2ч день</GreenBadge>
                        </Row>
                    
                        <Row>
                            <RowName>
                                <div>Прирост вашего дохода после обучения</div>
                            </RowName>
                            <Value >от Х2</Value>
                        </Row>
                    </Stats>
                </Info>
                {stopGettingContacts ? (
                     <RepairCardLink   style={{opacity:0.5}}> заявка отправлена </RepairCardLink>
                ): ( <RepairCardLink  onClick={() => setShowContact(true)} >Присоединиться </RepairCardLink>)}
               
                </div>
            ): (
                

                <div>
                    <Info style={{marginBottom:'30px'}}>
                    <RepairCardTitle>Заполните форму для связи с Вами</RepairCardTitle>
                    <Stats>
                        <Row>
                            <RowName>
                                <span>Имя</span>
                            </RowName>
                            <UranInput ref={inputRefName} type="text" />
                        </Row>
                    
                        <Row>
                            <RowName>
                                <div>Контакт для связи</div>
                            </RowName>
                            <UranInput  ref={inputRefContact} type="text" />
                        </Row>
                    </Stats>
                </Info>

                    <RepairCardLink  onClick={() => sendLead()} >Отправить </RepairCardLink>
                    </div>
                    
            )}
            

        </RepairCardComponent>
        
      {/* </List> */}
    </BaseHeadphoneModal>
  )
}

export default SmallHeadphoneModal;