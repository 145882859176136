import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo} from "react";
import {AppDispatch, RootState} from "../index";
import fetchUser from "./fetchUser";

export const getUser = (state:RootState) => state.user

export function useUserAction() {
    const dispatch = useDispatch<AppDispatch>()
    const getUserData = useCallback(() => {
        dispatch(fetchUser())
    }, [dispatch])
    return useMemo(() => ({
        getUserData,
        }),[
        getUserData,
    ])
}
export function useUserState() {
    return useSelector(getUser)
}

export function useUserData() {
    // const {isPlay} = useUserState()
    const {getUserData} = useUserAction()

    const getData = useCallback(() => {
        getUserData();
    }, [getUserData])

    useEffect(() => {
        getUserData()
        setTimeout(() => {
            getUserData()
        }, 10)

        const interval = setInterval(() => {
            getUserData()
        }, 20000)
        if (interval) {
            return () => clearInterval(interval)
        }
    }, [getData])
}


export function useUserDataOnce() {
    // const {isPlay} = useUserState()
    const {getUserData} = useUserAction()
    // getUserData()
    // setTimeout(() => {
    //     getUserData()
    // }, 10)
    

    const getData = useCallback(() => {
        getUserData();
    }, [getUserData])

    useEffect(() => {
        getUserData()
        setTimeout(() => {
            getUserData()
        }, 10)
      
    }, [getData])
}
