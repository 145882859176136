

import styled from "styled-components";
import {Link} from "react-router-dom";

export const DivImage = styled.div`
    margin: 0 auto;
    display: block;
    position: absolute;
    margin-top: -50px;
    height: 100px;
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
`

export const ImageCatcherWrapper = styled.div`
    background-size: cover;
    position: absolute;
    bottom: 70px;
    left: 42%;
    width: 100px;
    height: 20px;
    background-color: rgba(0,0,0,0.1);
    border-radius: 5px;
    transform: translateX(-50%);
    z-index: 900;
`
export const ImageCatcher = styled.img`
   
`
export const Image = styled.img`
    margin: 0 auto;
    display: block;
    top: 0;
    button: 0;
`

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    background:#fff;
    background-size: auto 100%;
`


export  const Title = styled.h2`
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    text-transform: uppercase;
`

export  const Subtitle = styled.h3`
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.1);
`

export  const Panel = styled.div`
    max-width: 320px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, rgba(255, 255, 255, 0.5332) 100%);
    backdrop-filter: blur(2px);
    border-radius: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 12px;
`

export const ButtonShare = styled(Link)`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    background: #FAFAFA;
    width: 53px;
    height: 53px;
    outline: none;
`
export const PanelTitle = styled.h3`
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
`

export const ProfitTitle = styled(PanelTitle)`
    color: rgba(255, 255, 255, 0.5);
`


export  const GradientBlock = styled.div`
    background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
    border-radius: 50px;
    display: flex;
    gap: 12px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    padding: 10px 21px 10px 8px;
    min-width: 280px;
`

export  const Button = styled.button`
    min-width: 54px;
    height: 54px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(2px);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    color: #000;
`
export const GradientText = styled.button`
        background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        font-size: 36px;

`

export const GradientTextBlack =  styled.button`
    background: linear-gradient(90deg, #000000 0%, #000000 17.71%, #000000 34.9%, #000000 49.48%, #000000 65.62%, #000000 82.81%, #000000 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.5;
`
export const Navbar = styled.div`
    width: 100%;
    padding: 0 16px;
    display: flex;
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    align-items: center;
    align-content: center;
    justify-content: space-between;
    z-index: 10;
`

export  const NavItem = styled.a`
    display: flex;
    border-radius: 60px;
    min-width: 53px;
    min-height: 53px;
    padding: 17px;
    align-items: center;
    width: fit-content;
    align-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);

    svg {
    width: 18px;
    height: 18px;

    path {
        fill: #fff;
    }
    }
`

export const Right = styled.div`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;
    gap: 10px;
`

export const PauseBTN = styled.div`
cursor:pointer;
font-weight: 500;
font-size: 14px;
color: #fff;
height:30px;
align-items: center;
display:flex;

flex-direction: row;
text-align:center;
background: rgba(0,0,0,0.2);
padding:10px 20px 8px 20px;
border-radius:10px;
justify-content: space-between;

span {
    font-size:34px;
}
div{
    font-size:16px;
}

`


export  const Map = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: row;
    right: 20px;
    top: 63px;
    width:30vw;
    z-index: 99;
    gap: 10px;
`
export  const Money = styled.div`
    font-size: 20px;
    color: rgba(0,0,0, 0.5);
    font-weight: 500;
    width: 100%;
    text-align: center;

` 
export const MoneyHeader = styled.div`
    font-size: 14px;
    color: rgba(0,0,0, 0.3);
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin-top: 10px;

`

export const ChooseYourBagToPlay = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0px;
    color: rgba(0,0,0, 0.1);
    left: 0px;
    width: 100vw;
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
`
export const PickerChooseItem = styled.div<{ selected: boolean }>`
    transform: ${({ selected }) => (selected ? 'scale(1.1)' : 'scale(0.8)')};
    opacity: ${({ selected }) => (selected ? '1' : '0.5')};
    display: flex;
    transition: transform 0.2s ease-in-out;
    color:rgba(0,0,0, 0.5);
    align-items: center;
    justify-content: center;
    gap: 10px;


    span{
        font-size: 12px;
         color:rgba(0,0,0, 0.5);
    }
   
    .name {
        font-size: 16px;
        color:rgba(0,0,0, 0.9);
        text-transform: capitalize;
    }
    .capacity{
        font-size: 12px;
    }
    .hp {
        font-size: 8px;
        display: flex;
        gap: 5px;
    }
    .line-2{
        display: flex ;
        flex-direction: row !important;
        gap: 5px;
        align-items: center;
        text-align: center;
        justify-content: center;
    }
`

export const StartGame = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    gap: 10px;
    transform: translate(-50%, -50%);
    color: rgba(0,0,0, 1);
    width: 100vw;
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 10px;
    cursor: pointer;
`
