import styled, {css} from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  background: #fff;
  width: 100%;
  padding-bottom:100px;

  * {
    color: #000;
  }

  @media (max-width: 394px) {
    overflow-x: hidden;
  }
`

export const ContainerBlack = styled(Container)`
  background: radial-gradient(50% 50% at 50% 50%, #252525 0%, #121212 100%);
  * {
    color: #fff;
  }
`
export const ContainerWhite = styled(Container)`
  background: rgba(255, 255, 255, 0.22);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.3px);
  -webkit-backdrop-filter: blur(5.3px);
  * {
    color: #fff;
  }
`
export const Level = styled.div`
  padding: 80px 7px;
  align-items: center;
  position: relative;
  gap: 40px;
  display: flex;
  flex-direction: column;
  max-width: 375px;
  margin: 0 auto;
  @media (max-width: 394px) {
    padding: 80px 5px
  }
`
export const LevelBlack = styled.div`
  padding: 66px 27px 0 29px;
  border-radius: 40px 40px 0px 0px;
  position: relative;
  gap: 44px;
  display: flex;
  flex-direction: column;
  max-width: 375px;
  margin: 0 auto;

  h2 {
    color: #ffffff;
    font-size: 44px;
    line-height: 54px;
    font-family: 'HelveticaNeue', 'Poppins';
    font-weight: bold;
    margin: 8px 0;
  }

  @media (max-width: 394px) {
    padding: 66px 5px 0 5px;
  }
`

export const LabelBlack = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 33px;
  color: #99A0A8;
  font-family: 'HelveticaNeue', 'Poppins';

  img{
    width: 100%;
    max-width: 200px;
  }
`

export const LabelRainbow = styled.p`
  background: linear-gradient(90deg, #FC9271 17.4%, #FD889F 26.8%, #FD82C3 48%, #E177D1 73.2%, #AA65F6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'HelveticaNeue', 'Poppins';
  font-size: 32px;
  line-height: 39px;
  font-weight: bold;
`

export const Photo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
`

export const RainbowCircle = styled.div`
  position: absolute;
  border-radius: 50%;
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  filter: blur(49.5px);
  transform: rotate(90deg);
  width: 356px;
  height: 355px;
  top: 13%;
  left: 20%;
  z-index: -1;
`

export const rainbowBorder = css`
  background: #FFFFFF;
  box-shadow: 0 20px 64px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  background: linear-gradient(90deg, #FDC558, #FCA35D, #FC9271, #FD889F, #FD82C3, #E177D1, #AA65F6);
  z-index: 0;

  &:before {
    content: '';
    background: #fff;
    border-radius: 13px;
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    bottom: 1.5px;
    right: 1.5px;
    z-index: -1;
  }
`
export const rainbowBorderBlack = css`
  ${rainbowBorder};

  &:before {
    background: #1D1D1D;
  }
`

export const CodeBlock = styled.div`
  display: flex;
  width: 100%;
  column-gap: 9px;
   max-width: 230px;
  margin: 0 auto;
  display: flex;
  @media (max-width: 394px) {
    justify-content: center;
  }
`
export const CodeBlockBarcode = styled.div<{isBlack?: boolean}>`
  padding: 14px;
  ${({isBlack}) => isBlack ? rainbowBorderBlack : rainbowBorder}
`
export const CodeBlockCode = styled.div<{isBlack?: boolean}>`
  padding: 18px 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({isBlack}) => isBlack ? rainbowBorderBlack : rainbowBorder}
`

export const StatBlock = styled.div<{isBlack?: boolean}>`
  padding: 18px 22px;
  line-height: 20px;
  width: 100%;
  ${({isBlack}) => isBlack ? rainbowBorderBlack : rainbowBorder}
`

export const CodeBlockCodeKey = styled.p`
  font-size: 16px;
  font-family: 'HelveticaNeue';
  font-weight: 400;
`

export const Product = styled.div`

  h2 {
    color: #050505;
    font-size: 44px;
    line-height: 54px;
    font-family: 'HelveticaNeue', 'Poppins';
    margin: 8px 0;
    font-weight: bold;
  }
`

export const ProductName = styled.div`
  font-size: 18px;
  line-height: 22px;
  font-family: 'open-sans-semibold';
  color: #686868;
`
export const ProductText = styled.p`
  font-size: 18px;
  line-height: 21px;

  font-family: 'HelveticaNeue';
  font-weight: 400;
  color: #000000;
`
export const ProductInfo = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  padding: 20px 19px 20px 17px;
  gap: 25px;
  border: 1.8px solid #E4E4E4;
  border-radius: 30px;
`

export const ProductDataPhoto = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 71px;
    height: 95px;
  }
`


export const BtnBlack = styled.div`
`

export const ProductInfoData = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  ${BtnBlack} {
    margin-top: 20px;
  }
`

export const ProductInfoDataRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:first-child {
    margin-bottom: 15px;
  }
`

export const ProductInfoDataRowModel = styled.p`
  letter-spacing: -0.01em;
  color: #000000;
  font-size: 14px;
  line-height: 17px;
  font-family: 'HelveticaNeue', 'Poppins';
  font-weight: bold;
`

export const ProductInfoDataRowNumber = styled.p`
  font-size: 12px;
  letter-spacing: -0.01em;

  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
`

export const ProductInfoDataRowText = styled.p`
  font-size: 12px;
  letter-spacing: -0.02em;

  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
  flex: 1;
  margin-left: 5px;
`

export const ProductInfoDataRowPrice = styled.div`
  width: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #55CE3E;
  border-radius: 40px;
  /* padding: 5px; */
  height: 22px;
  font-size: 10px;
  color: #fff;

  font-family: 'HelveticaNeue';
  font-weight: 400;
  letter-spacing: -0.02em;
`

export const ProductInfoDataRowBattery = styled.div`
  display: flex;
  border: 1px solid #000000;
  border-radius: 40px;
  /* padding: 5px 8px; */
  height: 22px;
  width: 63px;
  font-size: 10px;
  text-align: center;
  align-items: center;
  justify-content: center;

  font-family: 'HelveticaNeue';
  font-weight: 400;
  letter-spacing: -0.02em;
`
export const ProductInfoDataBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  width: 181px;
  height: 34px;
  background: radial-gradient(50% 50% at 50% 50%, #252525 0%, #121212 100%);
  border-radius: 40px;
  color: #FFFFFF;
  letter-spacing: -0.03em;
  line-height: 14px;
  font-size: 12px;
  margin-top: 20px;

  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
`

export const Black = styled(CodeBlock)`
  max-width: 230px;
  margin: 0 auto;
  display: flex;
  img {
    filter: invert(1);
  }

  ${CodeBlockCodeKey} {
    color: #E1E1E1;
  }
`

export const UpgradeBlock = styled.div``

export const UpgradeBlockTitle = styled.div`
  display: flex;
  background: radial-gradient(50% 50% at 50% 50%, #FFCD1B 0%, #FFBB36 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 44px;
  line-height: 54px;
  font-family: 'HelveticaNeue', 'Poppins';
  font-weight: bold;
  align-items: center;

  img {
    margin-left: 12px;
    padding-bottom: 10px;
  }
`

export const UpgradeBlockText = styled.p`
  margin-top: 18px;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
`
export const UpgradeBlockTable = styled.div`
  margin-top: 40px;
  font-size: 14px;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
`

export const UpgradeBlockTableTh = styled.div`
  display: flex;
  color: #ffffff;
  gap: 5px;
`

export const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding-top: 3px;
`

export const CellGradient = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 49px;
  color: #FFFFFF;
  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
  font-size: 14px;
  background: linear-gradient(90deg, rgb(253, 197, 88), rgb(252, 163, 93), rgb(252, 146, 113), rgb(253, 136, 159), rgb(253, 130, 195), rgb(225, 119, 209), rgb(170, 101, 246));
  border-radius: 10px;
`

export const CellRainbowBorder = styled(Cell)`
  height: 35px;
  background: #FFFFFF;
  box-shadow: 0px 20px 64px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #FAFAFA;
  position: relative;
  background: linear-gradient(90deg, rgb(253, 197, 88), rgb(252, 163, 93), rgb(252, 146, 113), rgb(253, 136, 159), rgb(253, 130, 195), rgb(225, 119, 209), rgb(170, 101, 246));
  z-index: 0;
  width: 49%;

  &::before {
    content: '';
    background: #202020;
    border-radius: 9px;
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    bottom: 1.5px;
    right: 1.5px;
    z-index: -1;
  }
`

export const WillShare = styled.div`
  padding-right: 40px;

  h3 {
    color: #EBEAEA;
    font-size: 26px;
    line-height: 32px;
    font-family: 'HelveticaNeue', 'Poppins';

    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`

export const WillShareLoad = styled.div`
  display: flex;
  margin-top: 26px;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #242424;
    border-radius: 40px;
    width: 197px;
    height: 52px;
    font-size: 16px;
    border: none;
    outline: none;
    p {
      font-size: 16px;
    }
  }

  img {
    margin-right: 10px;
  }
`
export const WillShareLoadVolume = styled.p`
  color: #3F3F3F;
  font-size: 16px;
  line-height: 19px;

  font-family: 'HelveticaNeue', sans-serif;
  font-weight: 400;
  margin-left: 24px;
`

export const IphoneBlock = styled.div`
  display: flex;
  position: relative;
  height: 564px;

  img {
    &:first-child {
      position: absolute;
      right: 165px;
      height: 438px;
    }

    &:last-child {
      position: absolute;
      right: -62px;
      height: 438px;
    }
  }
`
export const Statistics = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  width: 100%;

  h3 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 40px;
  }
`
export const StatisticsItem = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #2D2D2D;

  i {
    font-style: normal;
    font-weight: 500;
  }
`

export const SliderFull = styled.div`
    margin-bottom: 28px;
    max-width: 310px;
    width: 100%;
  
`
export const SliderArrows = styled.div`
  display: flex;
  gap: 38px;
  margin-top: 30px;
  justify-content: center;

  div {
    background: #E6E6E6;
    padding: 13px 11px;
    cursor: pointer;
    border-radius: 40px;
  }
  
`

export const SliderTitle = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  margin-bottom: 30px;
  color: rgba(48, 48, 48, 1);
  
  span {
    display: block;
    background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`

export const Slide = styled.div`
  padding: 0 30px;
  background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #F8F8F8 100%);
  border-radius: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 310px;

   i {
      display: inline-block;
      background: linear-gradient(96deg, #E177D1 10.98%, #AA65F6 90.34%);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      font-weight: 500;
      margin-bottom:4px;
      font-style: normal;
    }

  p {
    font-weight: 400;
    font-size: 22px;
    line-height: 26px;
    color: #5A5A5A;
  }

  b {
    margin-bottom:4px;
    font-style: normal;
    display: inline-block;
    background: linear-gradient(90deg, #FDC558 0%, #FCA35D 30.71%, #FC9271 60.9%, #FD889F 80.48%, #FD82C3 90.62%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  span {
    display: inline-block;
    background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
    color: transparent;
    font-weight: 700;
    -webkit-background-clip: text;
    background-clip: text;
     margin-bottom:4px;
  }
`