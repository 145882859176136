import React from 'react';
import { init } from '@telegram-apps/sdk';
import {Provider} from "react-redux";
import {BrowserRouter, useNavigate} from "react-router-dom";
import {store} from "./store";

try{
    init();
}
catch(e){console.log(e)}
  

  
function Providers({children}: any) {


  
    return (
        <Provider store={store}>
            <BrowserRouter>
                {children}
            </BrowserRouter>
        </Provider>
    );
}

export default Providers;
