import React, {FunctionComponent} from 'react';
import {Svg, SvgProps} from "..";

const Icon: FunctionComponent<SvgProps> = (props) => (
        <Svg width="8" height="12" viewBox="0 0 8 12" {...props} >
            <path d="M4.98182 0H3.10001C2.83417 0 2.53342 0.205981 2.42857 0.459632L0.0533253 6.2132C-0.101312 6.58776 0.091856 6.89194 0.484295 6.89194H3.80365C4.19635 6.89194 4.41419 7.20577 4.2901 7.5924L3.09763 11.3064C2.83307 12.1304 3.02573 12.2352 3.52783 11.5404L7.88179 5.51364C8.11735 5.18765 7.98981 4.92283 7.59745 4.92283H4.27788C3.88527 4.92283 3.68495 4.61568 3.83073 4.2373L5.28447 0.464295C5.38312 0.208136 5.24749 9.14215e-05 4.98165 9.14215e-05L4.98182 0Z" fill="url(#paint0_linear_706_2094)"/>
            <defs>
            <linearGradient id="paint0_linear_706_2094" x1="4" y1="0" x2="8.41212" y2="1.29985" gradientUnits="userSpaceOnUse">
            <stop stopColor="#EAB858"/>
            <stop offset="1" stopColor="#F89D42"/>
            </linearGradient>
            </defs>
        </Svg>
    );

export default Icon;