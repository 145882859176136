import {Link, useSearchParams} from "react-router-dom";
import React, {useState,useEffect} from 'react';
import i18next from 'i18next';
import styled from "styled-components";
import { YMaps, Map } from '@pbe/react-yandex-maps';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, {Settings} from "react-slick";
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import {useAppState} from "../../store/app/hooks";
import {
    BlockComponent,
    HeaderComponent,
    HeadphoneItemComponent, UserHeadphoneComponent,
    UserStatisticComponent,
    TokenComponent
} from "../../components/EduComponents";
import {useUserData, useUserState} from "../../store/user/hooks";
import {ArrowIcon} from "../../UI/Svg";
import Preloader from "../../views/RefViews/Preloader";
import PreloaderMax from "../../views/RefViews/PreloaderMax";
import MutedHeadphoneModal from '../../components/EduComponents/Modals/MutedHeadphoneModal';
import SellHeadphoneModal from '../../components/EduComponents/Modals/SellHeadphoneModal';
import { IUserPod } from '../../store/user/types';
import SmallHeadphoneModal from '../../components/EduComponents/Modals/SmallHeadphoneModal';
import BuyHeadphoneModal from '../../components/EduComponents/Modals/BuyHeadphoneModal';
import BannersComponent from '../../components/EduComponents/BannersComponent';

const ButtonShare = styled(Link)`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background: #FAFAFA;
  width: 53px;
  height: 53px;
  outline: none;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: flex-start;
  padding-bottom: 100px;
`

const BlockStyled = styled(BlockComponent)`
  padding: 0;

  & > h3 {
    padding-left: 15px;
  }
`

const List = styled.div`
  margin-top: 17px;
`

const ListUser = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  align-items: stretch;
  align-content: center;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 17px;
  max-width: unset;
  padding-right: 16px;
  padding-left: 16px;

  &::-webkit-scrollbar {
    display: none;
  }
`

const BlockVideo = styled.div`
    
    overflow: hidden;
    min-width: 350px;
`

const settings: Settings = {
    dots: false,
    infinite: false,
    // initialSlide: 0,
    speed: 500,
    swipe: true,
    arrows: false,
    slidesToShow: 3,
    centerMode: false,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 990,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 568,
            settings: {
                slidesToShow: 1.05,
            }
        },
    ]
};

function Index() {
    useUserData()
    const {user } = useUserState()
    const {token} = useAppState()
    const [loading, setLoading] = useState(false);
    const [selectedSellPod, setSelectedSellPod] = useState<IUserPod>();
    const [selectedUserPod, setSelectedUserPod] = useState<IUserPod>();
    const [selectedMutedPod, setSelectedMutedPod] = useState<IUserPod>();
    const [selectedBuyPod, setSelectedBuyPod] = useState<IUserPod>();
    

    const mutePodsFiltered = user?.mutepods.filter((mutepod) => !user.mypods.some((mypod) => mutepod.slug === mypod.slug));

    useEffect(() => {
        let interval: any
        if (loading) {
            interval = setInterval(() => {
                setLoading(false)
            }, 5000)
        }
    }, [loading])

    return (
        <Wrapper>
            <div style={{display:'flex',width:'100%',margin:10}}>
                <ButtonShare style={{transform: 'rotate(180deg)'}} to={`/?token=${token}`}>
                <ArrowIcon
                    width={32} height={32}/></ButtonShare>
                </div>
            <BlockStyled title='Карта с деньгами'>
            
            <YMaps>
                <div style={{margin:10,borderRadius:20,overflow:'hidden',border:'1px solid gray'}}>
                    <Map 
                        width={400} defaultState={{ center: [55.75, 37.57], zoom: 9 }} 
                        />
                </div>
            </YMaps>
                 
            </BlockStyled>
       
             
        </Wrapper>
    );
}

export default Index;
