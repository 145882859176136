import {useState} from 'react';
import i18next from 'i18next';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import BaseHeadphoneModal from './BaseHeadphoneModal';
import { Image, Info, Row, Title, RowName, Stats } from '../UserHeadphoneComponent';
import { IUserPod } from '../../../store/user/types';
import { ChargeIcon, CoinsIcon } from '../../../UI/Svg';
import { AppUser } from '../../../api/user';
import { useUserState } from '../../../store/user/hooks';

interface IMutedHeadphoneModalProps extends IUserPod {
  onClose: () => void;
}

const ModalDescription = styled(motion.div)`
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`

const ModalActionLink = styled(motion.a)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  color: white;
  height: 52px;
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border-radius: 40px;
  margin-top: 40px;
`

const ModalPrice = styled.div`
  background: linear-gradient(180deg, #8ED25C 0%, #539D1C 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  margin-bottom: 10px;
`

const ModalValue = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #000;
`

const typeLink = 'card'

function SellHeadphoneModal ({image, id, items, onClose}: IMutedHeadphoneModalProps) {
  const [searchParams] = useSearchParams();
  const { user } = useUserState()
  function sellPod (mid: number) {
    const token = searchParams.get('token');
    return token && AppUser.sellPod(mid, token);
  }

    const [sid, setId] = useState(-1);
    return (
    <BaseHeadphoneModal onClose={onClose}>
      {/* <ModalPrice >+386 $</ModalPrice> */}
      <ModalDescription style={{textAlign:'center',fontSize:'20px'}}>
      {i18next.t('sell_headphone.choice')} 
        </ModalDescription>
        
        <Image src={image} style={{ maxHeight: '200px'}}/>
     
        <Info>
          <Stats >
            { items ? items.map((item, index) => (
                <Row style={{border:'1px solid rgb(252, 163, 93)', borderRadius:'10px',padding:10}} onClick={() => setId(item.id)} >
                  <RowName>
                      <span>{item?.name}</span> <span>| {item?.cost} $</span>  <span style={{fontWeight:700}}>| {item?.pnl} %</span>
                  </RowName>

                  {item.id === sid ? (
                    <ModalValue>
                      
                      <div  style={{padding:7,borderRadius:'3px',border:'1px solid rgb(170, 101, 246)', background:'rgb(170, 101, 246)'}}/> 
                    </ModalValue>
                  ) : (
                      <div  style={{padding:7,borderRadius:'3px', border:'1px solid rgb(252, 163, 93)', background:'fff'}}/> 
                  )}
                  
                  
              </Row>
            )): null}
              
              
          </Stats>
      </Info>
      {sid>0?(
        <ModalActionLink 
          onClick={() => {
            sellPod(sid);
            onClose();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }}
          >
          {i18next.t('sell_headphone.confirm')}  
        </ModalActionLink>
      ):(
        <ModalActionLink 
        onClick={() => alert(i18next.t('sell_headphone.choice1'))}
        style={{opacity:0.5}}
      >
        {i18next.t('sell_headphone.confirm')}  
      </ModalActionLink>
      )}
      
    </BaseHeadphoneModal>
  )
}

export default SellHeadphoneModal;