import styled from 'styled-components';
import BaseHeadphoneModal from './BaseHeadphoneModal';
import { IBanner } from '../../../store/user/types';

interface IBannerModalProps {
  banner: IBanner;
  onClose: () => void;
}

const Image = styled.img`
  display: block;
  max-height: 110px;
  margin: 20px auto 0;
`

const Title = styled.p`
  margin: 29px 0 21px;
  font-weight: 700;
  font-size: 21px;
  line-height: 26px;
  color: #060606;
  text-align: center;
`

const Text = styled.p`
  text-align: center;
  font-size: 16px;
  color: #242424;
  line-height: 19px;
  margin-bottom: 36px;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  color: #FFFFFF;
  font-family: 'open-sans-semibold';
  font-size: 16px;
  line-height: 20px;
  background: linear-gradient(90deg, #FDC558 0%, #FCA35D 17.71%, #FC9271 34.9%, #FD889F 49.48%, #FD82C3 65.62%, #E177D1 82.81%, #AA65F6 99.48%);
  border-radius: 50px;
`


function BannerModal ({banner, onClose}: IBannerModalProps) {
  return (
    <BaseHeadphoneModal onClose={onClose}>
        <Image src={banner.image} />
        <Title>
            {banner.title}
        </Title>
        <Text>
            {banner.text}
        </Text>
        <Link href={banner.url}>
          {banner.button}
        </Link>
    </BaseHeadphoneModal>
  )
}

export default BannerModal;