import React from 'react';
import i18next from "i18next";
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Providers from "./Providers";

import commonSp from "./translations/sp/common.json";
import commonEn from "./translations/en/common.json";
import commonRu from "./translations/ru/common.json";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Providers>
          <App />
      </Providers>
  </React.StrictMode>
);

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: commonEn,               // 'common' is our custom namespace
          translation: commonEn
      },
      ru: {
          common: commonRu,
          translation: commonRu
      },
      sp: {
          common: commonSp,
          translation: commonSp
      },
  },
});


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
