import React, { useState } from 'react';
import styled from 'styled-components';
import '../styles/chart.css';

const ChartContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  position: relative;
  padding: 0.75rem;
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  height: 200px;
  border-left: 1px dashed rgba(0, 0, 0, 0.2);
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  
`;

const CandleButton = styled.button<{ isActive: boolean; height: number; bottom: number; isPositive: boolean }>`
  height: ${({ height }) => `${height}px`};
  width: 15%;
  bottom: ${({ bottom }) => `${bottom}px`};
  border: ${({ isActive }) => (isActive ? '1px solid rgba(0,255,0,0.3)' : '0px')};
  background-color: ${({ isPositive }) => (isPositive ? 'rgba(34,197,94,1)' : 'rgba(234,88,12,1)')};
  &:hover {
    background-color: ${({ isPositive }) => (isPositive ? 'rgba(22,163,74,1)' : 'rgba(194,65,12,1)')};
  }
  position: relative;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s;
`;

const CandleLabel = styled.div`
  position: absolute;
  top: -25px;
  font-size: 10px;
  width: 100%;
  text-align: center;
  color: rgba(113, 128, 150, 1);
`;

const LoadingNew = styled.div<{ bottom: number }>`
  height: 10px;
  width: 15%;
  bottom: ${({ bottom }) => `${bottom}px`};
  background-color: rgba(34,197,94,1);
  &:hover {
    background-color: rgba(22,163,74,1);
  }
  position: relative;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
`;

const YAxisLabel = styled.div`
  position: absolute;
  left: 2px;
  top: -25px;
  bottom: 0;
  width: 1px;
  font-size: 0.875rem;
  color: rgba(113, 128, 150, 1);
`;

const ActiveElementInfo = styled.div`
  position: absolute;
  top: 0;
  left: 10;
  background-color: rgba(39, 39, 42, 0.2);
  padding: 0.5rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  div{
    color: rgba(0, 0, 0, 0.5);
  }
`;

export function Chart() {
  const [activeElement, setActiveElement] = useState<{ id: number; y0: number; y1: number; name: string; x: number; y: number; info: any[] } | null>(null);
  const [loadingNew, setLoadingNew] = useState(true);
  const maxHeight = 200;
  const startValue = 240;
  const chartData = [
    { id: 1, name: 'Page A', x: 4000, y: 100, info: ['Продали оптом 10шт'] },
    { id: 2, name: 'Page B', x: 3000, y: 30, info: ['Предзаказ на футболку'] },
    { id: 3, name: 'Page C', x: 2000, y: 50, info: ['Увеличили эффективность'] },
    { id: 4, name: 'Page D', x: 2780, y: 10, info: ['Продали футболку'] },
    { id: 5, name: 'Page E', x: 1890, y: -30, info: ['Убытки из-за возвратов'] },
    { id: 6, name: 'Page F', x: 2390, y: 20, info: ['Выкуп предзаказа 65'] },
    { id: 7, name: 'Page G', x: 3490, y: 56, info: ['Пониженный % брака'] },
  ];

  const height = chartData.map(item => Math.abs(item.y)).reduce((acc, item) => acc + item, 0);

  const calculateCandleChartData = (data: { id: number, name: string, x: number, y: number, info: any[] }[]) => {
    let previousY = 0;
    return data.map(item => {
      const normalizedY = (item.y / height) * maxHeight;
      const newItem = { ...item, y0: previousY, y1: previousY + normalizedY };
      previousY = newItem.y1;
      return newItem;
    });
  };

  const candleChartData = calculateCandleChartData(chartData);

  return (
    <ChartContainer>
      {candleChartData.map((item, index) => (
        <CandleButton
          key={index}
          onClick={() => (item?.id === activeElement?.id ? setActiveElement(null) : setActiveElement(item))}
          height={Math.abs(item.y1 - item.y0)}
          bottom={item.y1 - item.y0 >= 0 ? item.y0 : item.y1}
          isActive={item?.id === activeElement?.id}
          isPositive={item.y > 0}
        >
          <CandleLabel>{item.y}$</CandleLabel>
        </CandleButton>
      ))}
      {loadingNew && (
        <LoadingNew bottom={candleChartData[6].y1} className='blob'>
          {/* <ArrowUpIcon className='absolute ' height={18} style={{top:-20,opacity:0.2}}/> */}
          {/* <div style={{top:'-25px', fontSize:10}} className='absolute w-full  text-center text-sm text-zinc-500'>{}$</div> */}
        </LoadingNew>
      )}
      {activeElement && (
        <ActiveElementInfo>
          <div className=''>{activeElement.info.join(', ')}</div>
        </ActiveElementInfo>
      )}

      <YAxisLabel>${startValue + height}</YAxisLabel>
      <div className='text-sm text-zinc-700' style={{ position: 'absolute', left: '0', right: '0', bottom: '-8px', height: '1px',  }}>${startValue}</div>
      {/* Подписи осей */}
      <div className='text-sm text-zinc-700' style={{ position: 'absolute', left: '-25px', top: '50%', transform: 'translateY(-50%)', writingMode: 'vertical-rl', textAlign: 'center' }}>Цена</div>
      <div className='text-sm text-zinc-700' style={{ position: 'absolute', left: '50%', bottom: '-25px', transform: 'translateX(-50%)', textAlign: 'center' }}>События</div>

    </ChartContainer>
  );
}